<template>
  <!-- GiftList -->
  <div id="loadingDiv" class="flex justify-center items-center pt-24" hidden>
    <div class="loadingio-spinner-eclipse-s4dxav6xm1i flex justify-center items-center">
      <div class="ldio-s4dxav6xm1i">
        <div></div>
      </div>
    </div>
  </div>


  <div id="giftDiv" class="flex flex-wrap gap-2 sm:gap-4 p-4 self-center place-content-center place-items-center" hidden>
    <div v-for="gift in gifts" :key="gift.id" :value="gift.id" class="h-fit w-40 sm:w-52 bg-white">
      <FriendAssignItem :friend="friend" :gift="gift"></FriendAssignItem>
    </div>
  </div>
</template>

<script>
import { API } from 'aws-amplify';

import FriendAssignItem from "@/components/FriendAssignItem.vue";

export default {
  name: "FriendAssign",
  props: {
    friendID: {
      type: String,
      default() {
        return ""
      }
    },
  },
  components: {
    FriendAssignItem,
  },
  data() {
    return {
      giftImages: {},
      gifts: {},
      isLoading: true,
      friend: {},
    }
  },
  computed: {
  },
  watch: {
    isLoading() {
      if (this.isLoading === false) {
        console.log("Loading: " + this.isLoading)
        document.querySelector("#giftDiv").removeAttribute("hidden")
        document.querySelector("#loadingDiv").style.display = 'none';
      }
    }
  },
  created() {
    this.gifts = this.getFriends()
  },
  methods: {
    async getFriends() {
      const query = `query MyQuery {
  listFriends {
    items {
      id
      name
      email
      birthday
      reminder
      anniversary
      anniversaryReminder
      updatedAt
      GiftLink {
        items {
          id
          purchased
          gift {
            name
            id
            price
            url
            image
            description
          }
        }
      }
    }
  }
}`
      let friends = await API.graphql({
        query: query
      });
      var sortedFriends = friends.data.listFriends.items.sort(
        function (a, b) {
          let nameA = a.name.toUpperCase();
          let nameB = b.name.toUpperCase();

          if (nameA < nameB) {
            return -1;
          }

          if (nameA > nameB) {
            return 1;
          }
        }
      );
      this.$store.dispatch("updateFriends", sortedFriends);
      this.friend = this.$store.state.friendMap.find(item => item.id === this.friendID)
      console.log(this.friend)
      this.gifts = this.sortGifts(this.$store.state.giftMap, this.friend)
      this.isLoading = false;
    },
    sortGifts(gifts, friend) {
      // Create an object with gift ids as keys and their purchased status as values
      const friendGiftStatus = friend.GiftLink.items.reduce((acc, item) => {
        acc[item.gift.id] = item.purchased;
        return acc;
      }, {});

      return gifts.sort((a, b) => {
        const aInFriendGifts = a.id in friendGiftStatus;
        const bInFriendGifts = b.id in friendGiftStatus;
        const aPurchased = aInFriendGifts ? friendGiftStatus[a.id] : false;
        const bPurchased = bInFriendGifts ? friendGiftStatus[b.id] : false;

        // Order: 1) Unpurchased in friendGiftIds, 2) Purchased in friendGiftIds, 3) Not in friendGiftIds
        if (aInFriendGifts && !aPurchased && (!bInFriendGifts || bPurchased)) {
          return -1;
        } else if (bInFriendGifts && !bPurchased && (!aInFriendGifts || aPurchased)) {
          return 1;
        } else if (aInFriendGifts && aPurchased && !bInFriendGifts) {
          return 1;
        } else if (bInFriendGifts && bPurchased && !aInFriendGifts) {
          return -1;
        } else {
          // Fallback to alphabetical sort if other conditions are not met
          return a.name.localeCompare(b.name);
        }
      });
    }

  },
};
</script>