import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import VueGtag from "vue-gtag";

import { Amplify } from "aws-amplify";
import awsExports from "./aws-exports";

import * as Sentry from "@sentry/vue";

import './index.css'

import Store from './store';
import './registerServiceWorker'

const app = createApp(App)

Sentry.init({
    app,
    dsn: "https://7bca4abf88a649ea819386407dbc224e@o4505306918879232.ingest.sentry.io/4505306932641792",
    integrations: [
      new Sentry.BrowserTracing({
        // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: ["dev.presentplanner.co", "presentplanner.co", /^\//],
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      }),
      new Sentry.Replay(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    environment: "dev",
  });

Amplify.configure(awsExports);

app.use(router).use(Store).use(VueGtag, {config: { id: "AW-11227042698/BR0yCJG0ubwYEIqnvOkp" }}).mount("#app");
