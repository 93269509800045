<template>
    <div class="relative items-center">
        <div class="relative flex items-center justify-center">
            <div :id="'loadingDiv' + gift.id" class="loadingio-spinner-eclipse-fdr3b2jliw9">
                <div class="ldio-xgxu3y3rvis">
                    <div></div>
                </div>
            </div>
        </div>
        <div :id="'imageDiv' + gift.id" hidden>
            <a v-if="gift.url" :href="gift.url" target="_blank">
                <img @load="isLoading = false" :src="giftImage" :alt="gift.name" class="h-24 sm:h-48 w-full object-cover" />
            </a>
            <img
v-else @load="isLoading = false" :src="giftImage" :alt="gift.name"
                class="h-24 sm:h-48 w-full object-cover"
/>
        </div>
        <div class="w-full">
            <span
v-if="gift.price"
                class="absolute top-0 right-0 ml-0 float-right bg-green-100 text-green-800 text-right text-xs sm:text-sm font-semibold px-2.5 py-0.5 rounded dark:bg-red-200 max-w-1/3 dark:text-red-800"
>
                ${{ gift.price }}
            </span>
            <span
v-if="gift.url !== null"
                class="absolute invisible sm:visible top-0 left-0 mr-0 float-left bg-gray-100 text-grey-800 text-xs sm:text-sm px-2.5 py-0.5 rounded dark:bg-red-200 dark:text-red-800 w-2/3 overflow-hidden whitespace-nowrap overflow-ellipsis"
>
                {{ getDomainName(gift.url) }}
            </span>
        </div>
    </div>
    <div class="px-2 py-2 h-8">
        <h2 class="float-left text-left text-xs sm:text-base font-semibold text-gray-800 line-clamp-2">
            {{ gift.name }}
        </h2>
    </div>
    <div class="px-2 py-2 h-16">
        <p class="float-left text-left text-xs text-gray-600 line-clamp-3">
            {{ gift.description }}
        </p>
    </div>

    <div class="rounded-md shadow-sm self-end" role="group">
        <button
type="button" @click="$router.push({ name: 'GiftAssign', params: { id: gift.id } })"
            class="px-4 py-2 text-xs sm:text-sm font-medium w-full sm:w-1/3 text-gray-900 bg-white border border-gray-200 rounded-bl-lg hover:bg-green-300 hover:text-black-700 focus:z-10 focus:ring-2 focus:ring-red-700 focus:text-red-700 dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-red-500 dark:focus:text-white"
>
            Assign
        </button>
        <button
type="button" @click="$router.push({ name: 'GiftEdit', params: { id: gift.id } })"
            class="px-4 py-2 text-xs sm:text-sm font-medium w-full sm:w-1/3 text-gray-900 bg-white border-t border-b border-gray-200 hover:bg-blue-300 hover:text-black-700 focus:z-10 focus:ring-2 focus:ring-red-700 focus:text-red-700 dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-red-500 dark:focus:text-white"
>
            Edit
        </button>
        <button
type="button" @click="deleteGift()"
            class="px-4 py-2 text-xs sm:text-sm font-medium w-full sm:w-1/3 text-gray-900 bg-white border border-gray-200 rounded-br-md hover:bg-red-300 hover:text-black-700 focus:z-10 focus:ring-2 focus:ring-red-700 focus:text-red-700 dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-red-500 dark:focus:text-white"
>
            Delete
        </button>
    </div>
</template>

<script>
import { API, graphqlOperation } from "aws-amplify";

import { onDeleteGift } from "../graphql/subscriptions";

import { getGift } from '../graphql/queries';

import { deleteGift, deleteGiftFriend } from '../graphql/mutations';

export default {
    name: "GiftItem",
    props: {
        gift: {
            type: Object,
            default() {
                return {}
            }
        },
    },
    components: {
    },
    data() {
        return {
            image: "",
            isLoading: true,
            giftUpdateSub: null,
            giftDeleteSub: null,
        };
    },
    setup() {

    },
    unmounted() {
        this.giftUpdateSub.unsubscribe();
        this.giftDeleteSub.unsubscribe();
    },
    created() {
        this.getImage(this.gift.image);
        this.subscribe();
    },
    computed: {
        gifts() {
            return this.$store.state.giftMap;
        },
        giftImage() {
            return this.image;
        }
    },
    watch: {
        isLoading() {
            if (this.isLoading === false) {
                document.querySelector("#imageDiv" + this.gift.id).removeAttribute("hidden")
                document.querySelector("#loadingDiv" + this.gift.id).setAttribute("hidden", true)
            }
        }
    },
    methods: {
        getDomainName(url) {
            const urlObj = new URL(url);
            let domain = urlObj.hostname;

            if (domain.startsWith('www.')) {
                domain = domain.slice(4);
            }

            return domain;
        },
        getImage(key) {
            if (key.includes("https://")) {
                this.image = key;
            } else if (key == '') {
                this.image = require(`@/assets/gift.png`);
            } else {
                this.image = '/public/' + key;
            };
        },
        async deleteGift() {
            if (confirm("Are you sure you want to delete " + this.gift.name + "?")) {
                try {

                    let giftLinks = await API.graphql({
                        query: getGift,
                        variables: { id: this.gift.id }
                    });

                    try {
                        giftLinks.data.getGift.FriendLink.items.forEach(function (item, index) {
                            const mutationInput = { "id": item.id };
                            API.graphql({ query: deleteGiftFriend, variables: { input: mutationInput }, });
                        });
                    } catch (error) {
                        console.log(error);
                    };

                } catch (error) {
                    console.log('Error deleting gift link...', error)
                };

                try {
                    var response = await API.graphql({ query: deleteGift, variables: { input: { id: this.gift.id } } });
                } catch (error) {
                    console.log('Error deleting gift...', error)
                };
            } else {
                return;
            }

        },
        subscribe() {
            const subToGiftUpdate = `subscription subToGiftUpdate {
        onUpdateGift(filter: {id: {eq: "${this.gift.id}"}}) {
          id
          owner
          image
          price
          description
          url
          name
        }
      }`

            this.giftUpdateSub = API.graphql(
                graphqlOperation(subToGiftUpdate)
            ).subscribe({
                next: (eventData) => {
                    let gift = eventData.value.data.onUpdateGift;
                    this.$store.dispatch("updateGift", gift);
                    this.getImage(gift.image);
                },
            });

            this.giftDeleteSub = API.graphql({
                query: onDeleteGift,
                variables: { owner: this.$store.state.user.data.userid },
            }).subscribe({
                next: (eventData) => {
                    let gift = eventData.value.data.onDeleteGift;
                    this.$store.dispatch("deleteGift", gift);
                },
            });
        },
    },

};
</script>