<template>
  <div class="flex flex-wrap gap-2 sm:gap-4 py-4 px-1 self-center place-content-center place-items-center">
    <div
v-for="friend in friends" :key="friend.id"
      class="h-fit w-44 sm:w-56 border border-gray-300 rounded rounded-lg bg-white"
>
      <FriendItem :friend="friend"></FriendItem>
    </div>
    <div v-if="friends.length === 0">
      <p class="text-gray-500 text-xl text-center">
Get started by adding a friend or family member using the button above
      </p>
    </div>
  </div>
</template>

<script>
import { API } from 'aws-amplify';

import { onUpdateFriend, onCreateFriend, onCreateGiftFriend, onDeleteFriend, onDeleteGiftFriend } from "../graphql/subscriptions";

import FriendItem from "@/components/FriendItem.vue";

export default {
  name: 'FriendList',
  data() {
    return {
      form: {},
      friendCreateSub: null,
      friendUpdateSub: null,
      friendDeleteSub: null,
      giftFriendCreateSub: null,
      giftFriendDeleteSub: null,

    }
  },
  components: {
    FriendItem
  },
  computed: {
    friends() {
      return this.$store.state.friendMap;
    },
  },
  created() {
    this.getFriends();
    if (this.$store.state.user.loggedIn) {
      this.subscribe();
    }
  },
  mounted() {
  },
  unmounted() {
    this.friendCreateSub.unsubscribe();
    this.friendUpdateSub.unsubscribe();
    this.friendDeleteSub.unsubscribe();
    this.giftFriendCreateSub.unsubscribe();
    this.giftFriendDeleteSub.unsubscribe();
  },

  methods: {
    async getFriends() {
      const query = `query MyQuery {
  listFriends {
    items {
      id
      name
      email
      birthday
      reminder
      anniversary
      anniversaryReminder
      updatedAt
      GiftLink {
        items {
          id
          purchased
          gift {
            name
            id
            price
            url
            image
            description
          }
        }
      }
    }
  }
}`
      let friends = await API.graphql({
        query: query
      });
      var sortedFriends = friends.data.listFriends.items.sort(
        function (a, b) {
          let nameA = a.name.toUpperCase();
          let nameB = b.name.toUpperCase();

          if (nameA < nameB) {
            return -1;
          }

          if (nameA > nameB) {
            return 1;
          }
        }
      );
      this.$store.dispatch("updateFriends", sortedFriends);
    },

    subscribe() {
      this.friendCreateSub = API.graphql({
        query: onCreateFriend,
        variables: { owner: this.$store.state.user.data.userid },
      }).subscribe({
        next: (eventData) => {
          let friend = eventData.value.data.onCreateFriend;
          this.$store.dispatch("addFriend", friend);
        },
      });

      this.giftFriendCreateSub = API.graphql({
        query: onCreateGiftFriend,
        variables: { owner: this.$store.state.user.data.userid },
      }).subscribe({
        next: (eventData) => {
          this.getFriends()
        },
      });

      this.friendUpdateSub = API.graphql({
        query: onUpdateFriend,
        variables: { owner: this.$store.state.user.data.userid },
      }).subscribe({
        next: (eventData) => {
          let friend = eventData.value.data.onUpdateFriend;

          this.$store.dispatch("updateFriend", friend);
        },
      });

      this.friendDeleteSub = API.graphql({
        query: onDeleteFriend,
        variables: { owner: this.$store.state.user.data.userid },
      }).subscribe({
        next: (eventData) => {
          this.getFriends()
        },
      });

      this.giftFriendDeleteSub = API.graphql({
        query: onDeleteGiftFriend,
        variables: { owner: this.$store.state.user.data.userid },
      }).subscribe({
        next: (eventData) => {
          this.getFriends()
        },
      });

    },
  }
}
</script>