<template>
  <div v-if="dev_version" class="fixed bottom-20 left-0 border-t rounded-lg bg-gray-300 p-2">
    <b>DEBUG</b>
    <samp>
      <p>IsLoggedIn: {{ $store.state.user.loggedIn }}</p>
      <p>Version: {{ version }} -- dev: {{ dev_version }}</p>
      <p>User Data: {{ $store.state.user.data }}</p>
      <!-- <p>Store Data: {{ $store.state.friendMap }}</p> -->
    </samp>
  </div>
</template>

<script>
//import store from "../store";

export default {
  name: "Debug",
  components: {
  },

  data() {
    return {
      version: process.env.VUE_APP_VERSION,
      dev_version: (process.env.VUE_APP_ENVIRONMENT === "dev2"),
    }
  },
  created() {
  },
};
</script>