<template>
  <form
@submit.prevent="updateFriend"
    class="border sm:w-full md:w-2/5 border-gray-300 rounded p-4 rounded-lg bg-slate-50"
>
    <div class="mb-3">
      <label for="friendName" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Friend
        Name</label>
      <input
v-model="friendForm.name"
        class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-red-500 focus:border-red-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-red-500 dark:focus:border-red-500"
        id="friendName" aria-describedby="emailHelp"
>
    </div>

    <div class="mb-3">
      <label for="friendEmail" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Email</label>
      <input
v-model="friendForm.email" type="input"
        class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-red-500 focus:border-red-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-red-500 dark:focus:border-red-500"
        id="friendEmail"
>
    </div>

    <div class="mb-3">
      <label for="friendBirthday" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Birthday</label>
      <input
v-model="friendForm.birthday" type="date"
        class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-red-500 focus:border-red-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-red-500 dark:focus:border-red-500"
        id="friendBirthday"
>
    </div>

    <div class="mb-3 mt-4 flex items-center">
      <input
id="reminder" type="checkbox" v-model="friendForm.reminder" :disabled="birthdaySet == false"
        class="form-checkbox h-5 w-5 text-blue-600"
>
      <div class="ml-2 text-sm">
        <label
for="reminder"
          :class="[birthdaySet == false ? ['block', 'text-sm', 'font-medium', 'text-gray-400', 'dark:text-white', 'pl-2'] : ['block', 'text-sm', 'font-medium', 'text-gray-900', 'dark:text-white', 'pl-2']]"
>
          Remind me before their birthday
        </label>
        <p id="reminder-checkbox-text" class="text-xs font-normal text-gray-500 dark:text-gray-300 pl-2">
          We'll send you a reminder 2 weeks before their birthday
        </p>
      </div>
    </div>

    <div class="mb-3">
      <label for="friendBirthday" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Anniversary</label>
      <input
v-model="friendForm.anniversary" type="date"
        class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-red-500 focus:border-red-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-red-500 dark:focus:border-red-500"
        id="friendBirthday"
>
    </div>

    <div class="mb-3 mt-4 flex items-center">
      <input
id="reminderAnniversary" type="checkbox" v-model="friendForm.anniversaryReminder"
        :disabled="anniversarySet == false" class="form-checkbox h-5 w-5 text-blue-600"
>
      <div class="ml-2 text-sm">
        <label
for="reminderAnniversary"
          :class="[anniversarySet == false ? ['block', 'text-sm', 'font-medium', 'text-gray-400', 'dark:text-white', 'pl-2'] : ['block', 'text-sm', 'font-medium', 'text-gray-900', 'dark:text-white', 'pl-2']]"
>
          Remind me before this anniversary
        </label>
        <p id="reminder-checkbox-text" class="text-xs font-normal text-gray-500 dark:text-gray-300 pl-2">
          We'll send you a reminder 2 weeks before your anniversary
        </p>
      </div>
    </div>

    <div class="flex items-center p-1 py-2 space-x-2 rounded-b border-t border-b border-gray-200 dark:border-gray-600">
      <button
@click="updateFriend" type="button"
        class="text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm w-1/2 sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-red-600 dark:focus:ring-red-800"
>
        Save
      </button>
      <button
type="button" @click="$router.go(-1)"
        class="text-gray-600 bg-white hover:bg-gray-100 rounded-lg border border-gray-200 text-sm w-1/2 sm:w-auto font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600"
>
        Cancel
      </button>
    </div>
  </form>
</template>

<script>
import { API } from 'aws-amplify';

import { updateFriend, deleteGiftFriend } from '../graphql/mutations';

export default {
  name: "FriendEdit",
  props: {
    friend: {
      type: Object,
      default() {
        return {}
      }
    },
  },
  components: {
  },
  computed: {
    birthdaySet() {
      return this.friendForm.birthday ? true : false;
    },
    anniversarySet() {
      return this.friendForm.anniversary ? true : false;
    }

  },
  created() {
    this.friendForm = { ...this.friend }
  },
  data() {
    return {
      friendForm: {},
    };
  },
  methods: {
    async deleteGiftFriend(giftLinkId, giftLinkVersion) {
      const mutationInput = { "id": giftLinkId };
      try {
        const response = await API.graphql({ query: deleteGiftFriend, variables: { input: mutationInput }, });
      } catch (error) {
        console.log('Error deleting gift...', error)
      }
    },
    async updateFriend() {
      const friendData = {
        id: this.friend.id,
        name: this.friendForm.name,
        email: this.friendForm.email,
        birthday: this.friendForm.birthday,
        reminder: this.friendForm.reminder,
        anniversary: this.friendForm.anniversary,
        anniversaryReminder: this.friendForm.anniversaryReminder,
      };
      try {
        const response = await API.graphql({ query: updateFriend, variables: { input: friendData } });
        this.$router.go(-1)
      } catch (error) {
        console.log('Error creating gift...', error)
      }
    },
  },
};
</script>