<template>
  <Header />
  <section class="py-2 bg-gradient-to-b from-blue-50 to-transparent w-full h-full lg:pb-2 relative">
    <div class="py-8 px-4 mx-auto max-w-screen-xl text-center lg:py-16 z-10 relative">
      <h1
        class="mb-4 text-4xl font-extrabold tracking-tight leading-none text-gray-900 md:text-5xl lg:text-6xl dark:text-white"
>
        A better way to remember gift ideas
      </h1>
      <p class="mb-8 text-lg font-normal text-gray-500 lg:text-xl sm:px-16 lg:px-48 dark:text-gray-200">
        Save gift ideas and get reminders about upcoming birthdays and events
      </p>
      <form class="w-full max-w-md mx-auto">
        <label for="default-email" class="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white">Email
          sign-up</label>
        <div class="relative">
          <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
            <svg
class="w-5 h-5 text-gray-500 dark:text-gray-400" fill="currentColor" viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg" aria-hidden="true"
>
              <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z"></path>
              <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z"></path>
            </svg>
          </div>
          <input
type="email" id="default-email" v-model="registerEmail"
            class="block w-full p-4 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-white focus:ring-red-500 focus:border-red-500 dark:bg-gray-800 dark:border-gray-700 dark:placeholder-gray-400 dark:text-white dark:focus:ring-red-500 dark:focus:border-red-500"
            placeholder="Enter your email to get started..." required
>
          <button
type="submit" @click="redirectToRegister()"
            class="text-white absolute right-2.5 bottom-2.5 bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm px-4 py-2 dark:bg-blue-600 dark:hover:bg-red-600 dark:focus:ring-red-800"
>
            Sign
            up
          </button>
        </div>
      </form>
    </div>
  </section>

  <div class="container my-2 mx-auto md:px-6">
    <section class="mb-8">
      <div class="flex flex-col-reverse lg:flex-row flex-wrap">
        <div class="mb-12 w-full shrink-0 grow-0 basis-auto lg:mb-0 lg:w-5/12">
          <div class="flex lg:py-12">
            <img
src="@/assets/gifts.png" class="w-full rounded-lg shadow-lg dark:shadow-black/20 lg:ml-[50px] z-[10]"
              alt="Page of saved gifts"
/>
          </div>
        </div>
        <div class="w-full shrink-0 grow-0 basis-auto lg:w-7/12">
          <div class="flex h-full items-center rounded-lg bg-primary p-6 text-center text-black lg:pl-12 lg:text-left">
            <div class="lg:pl-12">
              <h2 class="mb-6 text-3xl font-bold">
                Capture the perfect gift ideas
              </h2>
              <p class="mb-6 pb-2 lg:pb-0">
                No more lost gift ideas or last-minute panic! Use our platform to keep track of all your potential gifts
                in one organized space. Our website ensures every present you give is thoughtful, unique, and most
                importantly, remembered.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>

  <div class="container my-2 mx-auto md:px-6">
    <section class="mb-8">
      <div class="flex flex-wrap">
        <div class="w-full shrink-0 grow-0 basis-auto lg:w-7/12">
          <div class="flex h-full items-center rounded-lg bg-primary p-6 text-center text-black lg:pr-12 lg:text-left">
            <div class="lg:pr-12">
              <h2 class="mb-6 text-3xl font-bold">
                Match gift ideas with your loved ones
              </h2>
              <p class="mb-6 pb-2 lg:pb-0">
                Our platform allows you to assign saved gift ideas to your loved ones, ensuring every gift is as unique as
                its recipient. Transform your gift-giving with our personalised approach, and make every occasion special.
              </p>
            </div>
          </div>
        </div>
        <div class="mb-12 w-full shrink-0 grow-0 basis-auto lg:mb-0 lg:w-5/12">
          <div class="flex lg:py-12">
            <img
src="@/assets/friends.png" class="w-full rounded-lg shadow-lg dark:shadow-black/20 lg:mr-[50px] z-[10]"
              alt="Page of friends and family"
/>
          </div>
        </div>
      </div>
    </section>
  </div>


  <div class="container my-2 mx-auto md:px-6">
    <section class="mb-8">
      <div class="flex flex-col-reverse lg:flex-row flex-wrap">
        <div class="mb-12 w-full shrink-0 grow-0 basis-auto lg:mb-0 lg:w-5/12">
          <div class="flex lg:py-12">
            <img
src="@/assets/upcoming.png" class="w-full rounded-lg shadow-lg dark:shadow-black/20 lg:ml-[50px] z-[10]"
              alt="A list of upcoming events and birthdays"
/>
          </div>
        </div>
        <div class="w-full shrink-0 grow-0 basis-auto lg:w-7/12">
          <div class="flex h-full items-center rounded-lg bg-primary p-6 text-center text-black lg:pl-12 lg:text-left">
            <div class="lg:pl-12">
              <h2 class="mb-6 text-3xl font-bold">
                Never forget special occasions
              </h2>
              <p class="mb-6 pb-2 lg:pb-0">
                Never miss an important date again! Our built-in reminder system keeps you on top of birthdays,
                anniversaries, and more. No more last-minute gift cards!
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>

  <div class="container my-2 mx-auto md:px-6">
    <section class="mb-8">
      <div class="flex flex-wrap">
        <div class="w-full shrink-0 grow-0 basis-auto lg:w-7/12">
          <div class="flex h-full items-center rounded-lg bg-primary p-6 text-center text-black lg:pr-12 lg:text-left">
            <div class="lg:pr-12">
              <h2 class="mb-6 text-3xl font-bold">
                Timely reminders for upcoming events
              </h2>
              <p class="mb-6 pb-2 lg:pb-0">
                Calendar reminders are clunky and unhelpful, not to mention they don't remind you which gifts to give. We send email reminders before every birthday, anniversary, Christmas, and more, providing a failsafe for those busy times.
              </p>
            </div>
          </div>
        </div>
        <div class="mb-12 w-full shrink-0 grow-0 basis-auto lg:mb-0 lg:w-5/12">
          <div class="flex lg:py-12">
            <img
src="@/assets/email.png" class="w-full rounded-lg shadow-lg dark:shadow-black/20 lg:mr-[50px] z-[10]"
              alt="An example email reminding the user of an upcoming birthday"
/>
          </div>
        </div>
      </div>
    </section>
  </div>

  <div class="container my-2 mx-auto md:px-2">
    <section class="mb-8">
      <div class="flex flex-wrap">
        <div class="w-full shrink-0 grow-0 basis-auto">
          <div class="flex h-full items-center rounded-lg bg-primary p-6 text-center text-black justify-center">
              <h2 class="text-3xl font-bold">
                Join over 1,200 users who have already signed up!
              </h2>
          </div>
        </div>

        <div class="grid md:grid-cols-5 gap-4 p-8">
          <div class="flex flex-col items-center p-4 bg-white w-full rounded-lg shadow-lg dark:shadow-black/20 lg:mr-[50px]">
            <h2 class="mt-4 text-lg font-bold">Sarah B</h2>
            <p class="mt-2 text-center italic">"This is the perfect solution for a forgetful mind! Now I can store all my gift ideas and get reminders too. A lifesaver!"</p>
          </div>
        
          <div class="flex flex-col items-center p-4 bg-white w-full rounded-lg shadow-lg dark:shadow-black/20 lg:mr-[50px]">
            <h2 class="mt-4 text-lg font-bold">Carly B</h2>
            <p class="mt-2 text-center italic">"I can't recommend this website enough. It has revolutionized the way I plan gifts throughout the year."</p>
          </div>
        
          <div class="flex flex-col items-center p-4 bg-white w-full rounded-lg shadow-lg dark:shadow-black/20 lg:mr-[50px]">
            <h2 class="mt-4 text-lg font-bold">Josh R</h2>
            <p class="mt-2 text-center italic">"Fantastic! This website turned me from a last-minute panic buyer to a thoughtful gifter."</p>
          </div>
      
          <div class="flex flex-col items-center p-4 bg-white w-full rounded-lg shadow-lg dark:shadow-black/20 lg:mr-[50px]">
            <h2 class="mt-4 text-lg font-bold">Emily</h2>
            <p class="mt-2 text-center italic">"An incredibly useful tool for keeping track of gift ideas. The event reminders are a big bonus!"</p>
          </div>
      
          <div class="flex flex-col items-center p-4 bg-white w-full rounded-lg shadow-lg dark:shadow-black/20 lg:mr-[50px]">
            <h2 class="mt-4 text-lg font-bold">Leanne T</h2>
            <p class="mt-2 text-center italic">"My go-to for keeping all my gifting ideas organized. Makes remembering special events a breeze!"</p>
          </div>
        </div>
      </div>
    </section>
  </div>

  


  <Footer />
</template>

<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";

//import Debug from "@/components/Debug.vue";

export default {
  name: "HomeView",
  components: {
    Header,
    Footer,
    //Debug,
  },
  data() {
    return {
      registerEmail: "",
    }
  },
  created() { },
  computed: {
    isLoggedIn() {
      return this.$store.state.user.loggedIn;
    },
  },
  methods: {
    redirectToRegister() {
      if (this.registerEmail === "") {
        this.$router.push({ name: "Register" });
      } else {
        this.$router.push({ name: "Register2", params: { email: this.registerEmail } });
      }
    },
  },
};
</script>
