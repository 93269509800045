/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getFriend = /* GraphQL */ `
  query GetFriend($id: ID!) {
    getFriend(id: $id) {
      birthday
      anniversary
      email
      id
      name
      GiftLink {
        items {
          id
          friendId
          giftId
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
      reminder
      anniversaryReminder
      lastReminder
      notifyRegistration
      owner
    }
  }
`;
export const listFriends = /* GraphQL */ `
  query ListFriends(
    $filter: ModelFriendFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFriends(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        birthday
        anniversary
        email
        id
        name
        GiftLink {
          nextToken
        }
        createdAt
        updatedAt
        reminder
        anniversaryReminder
        lastReminder
        notifyRegistration
        owner
      }
      nextToken
    }
  }
`;
export const getGift = /* GraphQL */ `
  query GetGift($id: ID!) {
    getGift(id: $id) {
      createdAt
      description
      id
      name
      price
      updatedAt
      FriendLink {
        items {
          id
          friendId
          giftId
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      url
      image
      owner
    }
  }
`;
export const listGifts = /* GraphQL */ `
  query ListGifts(
    $filter: ModelGiftFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGifts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        createdAt
        description
        id
        name
        price
        updatedAt
        FriendLink {
          nextToken
        }
        url
        image
        owner
      }
      nextToken
    }
  }
`;
export const getGiftFriend = /* GraphQL */ `
  query GetGiftFriend($id: ID!) {
    getGiftFriend(id: $id) {
      id
      friendId
      giftId
      friend {
        birthday
        anniversary
        email
        id
        name
        GiftLink {
          nextToken
        }
        createdAt
        updatedAt
        reminder
        anniversaryReminder
        lastReminder
        notifyRegistration
        owner
      }
      gift {
        createdAt
        description
        id
        name
        price
        updatedAt
        FriendLink {
          nextToken
        }
        url
        image
        owner
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listGiftFriends = /* GraphQL */ `
  query ListGiftFriends(
    $filter: ModelGiftFriendFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGiftFriends(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        friendId
        giftId
        friend {
          birthday
          anniversary
          email
          id
          name
          createdAt
          updatedAt
          reminder
          anniversaryReminder
          lastReminder
          notifyRegistration
          owner
        }
        gift {
          createdAt
          description
          id
          name
          price
          updatedAt
          url
          image
          owner
        }
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const giftFriendsByFriendId = /* GraphQL */ `
  query GiftFriendsByFriendId(
    $friendId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelGiftFriendFilterInput
    $limit: Int
    $nextToken: String
  ) {
    giftFriendsByFriendId(
      friendId: $friendId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        friendId
        giftId
        friend {
          birthday
          anniversary
          email
          id
          name
          createdAt
          updatedAt
          reminder
          anniversaryReminder
          lastReminder
          notifyRegistration
          owner
        }
        gift {
          createdAt
          description
          id
          name
          price
          updatedAt
          url
          image
          owner
        }
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const giftFriendsByGiftId = /* GraphQL */ `
  query GiftFriendsByGiftId(
    $giftId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelGiftFriendFilterInput
    $limit: Int
    $nextToken: String
  ) {
    giftFriendsByGiftId(
      giftId: $giftId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        friendId
        giftId
        friend {
          birthday
          anniversary
          email
          id
          name
          createdAt
          updatedAt
          reminder
          anniversaryReminder
          lastReminder
          notifyRegistration
          owner
        }
        gift {
          createdAt
          description
          id
          name
          price
          updatedAt
          url
          image
          owner
        }
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
