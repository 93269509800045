import { createRouter, createWebHistory } from "vue-router";

import HomeView from "../views/HomeView.vue";

import FriendAddView from "../views/FriendAddView.vue";
import FriendListView from "../views/FriendListView.vue";
import FriendEditView from "../views/FriendEditView.vue";
import FriendAssignView from "../views/FriendAssignView.vue";


import GiftAddView from "../views/GiftAddView.vue";
import GiftListView from "../views/GiftListView.vue";
import GiftEditView from "../views/GiftEditView.vue";
import GiftAssignView from "../views/GiftAssignView.vue";

import UpcomingView from "../views/UpcomingView.vue";

import AccountView from "../views/AccountView.vue";

//import SignUpCompleteView from "../views/SignUpCompleteView.vue";

import AuthView from "../views/AuthView.vue";

import TestView from "../views/TestView.vue";

const routes = [
  // HOME
  {
    path: "/",
    name: "Home",
    component: HomeView,
    meta: {
      title: ''
    }
  },

  // UPCOMING
  {
    path: "/upcoming",
    name: "Upcoming",
    component: UpcomingView,
    meta: {
      title: 'Upcoming'
    }
  },

  // PEOPLE

  {
    path: "/people",
    name: "People",
    component: FriendListView,
    meta: {
      title: 'People'
    }
  },
  {
    path: "/friend/add",
    name: "FriendAdd",
    component: FriendAddView,
    meta: {
      title: 'Add someone'
    }
  },
  {
    path: "/people/edit/:id",
    name: "PeopleEdit",
    component: FriendEditView,
    meta: {
      title: 'Edit Person'
    }
  },
  {
    path: "/people/assign/:id",
    name: "PeopleAssign",
    component: FriendAssignView,
    meta: {
      title: 'Assign Person'
    }
  },

  // GIFTS

  {
    path: "/gifts",
    name: "Gifts",
    component: GiftListView,
    meta: {
      title: 'Gifts'
    }
  },
  {
    path: "/gift/add",
    name: "GiftAdd",
    component: GiftAddView,
    meta: {
      title: 'Add a gift'
    }
  },
  {
    path: "/gift/edit/:id",
    name: "GiftEdit",
    component: GiftEditView,
    meta: {
      title: 'Edit Gift'
    }
  },
  {
    path: "/people/assign/:id",
    name: "GiftAssign",
    component: GiftAssignView,
    meta: {
      title: 'Assign Gift'
    }
  },

  // ACCOUNT

  {
    path: "/account",
    name: "Account",
    component: AccountView,
    meta: {
      title: 'Account'
    }
  },

  // AUTH

  {
    path: "/login",
    name: "Login",
    component: AuthView,
    meta: {
      title: 'Welcome back'
    }
  },
  {
    path: "/register",
    name: "Register",
    component: AuthView,
    meta: {
      title: 'Welcome'
    }
  },
  {
    path: "/register/:email",
    name: "Register2",
    component: AuthView,
    meta: {
      title: 'Welcome'
    }
  },
  //{
  //  path: "/completed",
  //  name: "Completed",
  //  component: SignUpCompleteView,
  //  meta: {
  //    title: 'Completed'
  //  }
  //},

  // TEST

  //{
  //  path: "/test",
  //  name: "Test",
  //  component: TestView,
  //  meta: {
  //    title: 'Test!!!!!!!'
  //  }
  //},
];

const router = createRouter({
  mode: "history",
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = "Present Planner - " + to.meta.title;
  } else {
    document.title = "Present Planner";
  }
  next();
});



export default router;
