<template>
    <div class="px-2 py-1 sm:py-2 h:8 overflow-hidden overflow-ellipsis whitespace-nowrap">
        <h2 class="text-md sm:text-xl font-semibold text-gray-800 truncate capitalize">
            {{ friend.name.toLowerCase() }}
        </h2>
    </div>
    <div class="px-2 h-6 sm:h-6 w-full">
        <span v-if="friend.birthday" class="flex float-left text-gray-600">
            <p v-if="daysUntilNextAnniversary(friend.birthday) < 1" class="text-sm sm:text-sm">Birthday <span
                    class="text-red-500 font-bold"
>today</span></p>
            <p v-else-if="daysUntilNextAnniversary(friend.birthday) < 2" class="text-sm sm:text-sm">Birthday <span
                    class="text-red-500 font-bold"
>tomorrow</span></p>
            <p v-else-if="daysUntilNextAnniversary(friend.birthday) < 14" class="text-sm sm:text-sm">Birthday in <span
                    class="text-red-500 font-bold"
>{{ daysUntilNextAnniversary(friend.birthday) }}</span> days</p>
            <p v-else class="text-sm sm:text-sm">Birthday in {{ daysUntilNextAnniversary(friend.birthday) }} days</p>
        </span>
        <span v-else></span>
    </div>
    <div class="px-2 h-6 sm:h-6 w-full">
        <span v-if="friend.anniversary" class="flex float-left text-gray-600">
            <p v-if="daysUntilNextAnniversary(friend.anniversary) < 1" class="text-sm sm:text-sm">Anniversary <span
                    class="text-red-500 font-bold"
>today</span></p>
            <p v-else-if="daysUntilNextAnniversary(friend.anniversary) < 2" class="text-sm sm:text-sm">Anniversary <span
                    class="text-red-500 font-bold"
>tomorrow</span></p>
            <p v-else-if="daysUntilNextAnniversary(friend.anniversary) < 14" class="text-sm sm:text-sm">Anniversary in <span
                    class="text-red-500 font-bold"
>{{ daysUntilNextAnniversary(friend.anniversary) }}</span> days</p>
            <p v-else class="text-sm sm:text-sm">Anniversary in {{ daysUntilNextAnniversary(friend.anniversary) }} days</p>
        </span>
        <span v-else></span>
    </div>
    <div class="px-2 pb-2 h-6 sm:h-8 w-full overflow-hidden overflow-ellipsis whitespace-nowrap">
        <span v-if="friend.email" class="flex float-left text-gray-600 text-xs sm:text-sm ">
            {{ friend.email.toLowerCase() }}
        </span>
        <span v-else></span>
    </div>

    <div class="rounded-md shadow-sm self-end" role="group">
        <button
type="button" @click="$router.push({ name: 'PeopleAssign', params: { id: friend.id } })"
            class="px-4 py-2 text-sm font-medium w-full sm:w-1/3 text-gray-900 bg-white border border-gray-200 rounded-bl-lg hover:bg-green-300 hover:text-black-700 focus:z-10 focus:ring-2 focus:ring-red-700 focus:text-red-700 dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-red-500 dark:focus:text-white"
>
            Gifts
        </button>

        <button
type="button" @click="$router.push({ name: 'PeopleEdit', params: { id: friend.id } })"
            class="px-4 py-2 text-sm font-medium w-full sm:w-1/3 text-gray-900 bg-white border-t border-b border-gray-200 hover:bg-blue-300 hover:text-black-700 focus:z-10 focus:ring-2 focus:ring-red-700 focus:text-red-700 dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-red-500 dark:focus:text-white"
>
            Edit
        </button>

        <button
type="button" @click="deleteFriend()"
            class="px-4 py-2 text-sm font-medium w-full sm:w-1/3 text-gray-900 bg-white border border-gray-200 rounded-br-md hover:bg-red-300 hover:text-black-700 focus:z-10 focus:ring-2 focus:ring-red-700 focus:text-red-700 dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-red-500 dark:focus:text-white"
>
            Delete
        </button>
    </div>
</template>

<script>
import { API } from "aws-amplify";

import { getFriend } from '../graphql/queries';

import { deleteFriend, deleteGiftFriend } from '../graphql/mutations';

export default {
    name: "FriendItem",
    props: {
        friend: {
            type: Object,
            default() {
                return {}
            }
        },
    },
    data() {
        return {
            form: {},
            image: "",
            showModal: false,
            imageNum: "",
        };
    },
    setup() { },
    created() {
    },
    computed: {
        friends() {
            return this.$store.state.friendMap;
        },
        friendImage() {
            return this.image
        }
    },
    methods: {
        daysUntilNextAnniversary(dob) {
            const today = new Date();
            today.setHours(0, 0, 0, 0);
            const birthday = new Date(dob);

            let nextBirthday = new Date(today.getFullYear(), birthday.getMonth(), birthday.getDate());

            if (today > nextBirthday) {
                nextBirthday.setFullYear(nextBirthday.getFullYear() + 1);
            }


            if (nextBirthday === today) {
                return 0;
            };

            const diffMillis = nextBirthday.getTime() - today.getTime();

            return Math.ceil(diffMillis / (1000 * 60 * 60 * 24));
        },

        async deleteFriend() {
            if (confirm("Are you sure you want to delete " + this.friend.name + "?")) {
                try {

                    let friendLinks = await API.graphql({
                        query: getFriend,
                        variables: { id: this.friend.id }
                    });

                    try {
                        friendLinks.data.getFriend.GiftLink.items.forEach(function (item, index) {
                            const mutationInput = { "id": item.id };
                            API.graphql({ query: deleteGiftFriend, variables: { input: mutationInput }, });
                        });
                    } catch (error) {
                    };

                } catch (error) {
                };

                try {
                    var response = await API.graphql({ query: deleteFriend, variables: { input: { id: this.friend.id } } });
                } catch (error) {
                    console.log('Error deleting friend...', error)
                };
            } else {
                return;
            }

        },
    },
};
</script>