<template>
  <!-- GiftItem -->
  <div
class="border rounded rounded-lg border-2" :class="{
    'border-blue-600': isAssignedAndNotPurchased,
    'border-green-600 opacity-50': isAssignedAndPurchased,
  }"
>
    <div class="relative items-center">
      <div :id="'imageDiv' + gift.id">
        <a v-if="gift.url" :href="gift.url" target="_blank">
          <img :src="getImageURL(gift.id)" class="h-24 sm:h-40 w-full object-cover" />
        </a>
        <img v-else :src="getImageURL(gift.id)" class="h-24 sm:h-40 w-full object-cover" />
      </div>
      <hr class="h-px bg-gray-200 border" />
      <div class="w-full">
        <span
v-if="gift.price"
          class="absolute top-0 right-0 ml-0 float-right bg-green-100 text-green-800 text-right text-xs sm:text-sm font-semibold px-2.5 py-0.5 rounded dark:bg-red-200 max-w-1/3 dark:text-red-800"
>
          ${{ gift.price }}
        </span>
        <span
v-if="gift.url !== null"
          class="absolute invisible sm:visible top-0 left-0 mr-0 float-left bg-gray-100 text-grey-800 text-xs sm:text-sm px-2.5 py-0.5 rounded dark:bg-red-200 dark:text-red-800 w-2/3 overflow-hidden whitespace-nowrap overflow-ellipsis"
>
          {{ getDomainName(gift.url) }}
        </span>
      </div>
    </div>

    <div class="px-2 py-2 h-14 ">
      <p class="float-left text-left text-xs sm:text-base font-semibold text-gray-800 line-clamp-2">
        {{ gift.name }}
      </p>
      <br />
    </div>

    <div class="rounded-md shadow-sm self-end flex pt-4 sm:pt-4 text-center" role="group">
      <div
        class="px-1 py-2 text-xs sm:text-sm font-medium w-1/2 text-gray-900 bg-white border border-gray-200 rounded-bl-lg hover:bg-blue-300 hover:text-black-700 focus:z-10"
>
        <input
@change="UpdateFriendLink($event, friend.id, friend.GiftLink.items)" type="checkbox"
          :id="'assign' + friend.id + gift.id" :value="gift.id" :checked="isAssigned"
          class="w-4 h-4 rounded text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 focus:ring-2"
/>

        <label
:for="'assign' + friend.id + gift.id" :value="gift.id"
          class="py-3 ms-2 text-xs sm:text-xs font-medium text-gray-900 dark:text-gray-300"
>Assigned</label>
      </div>

      <div
        class="px-1 py-2 text-xs sm:text-sm font-medium w-1/2 text-gray-900 bg-white border border-gray-200 rounded-br-lg focus:z-10"
        :class="{ 'hover:bg-green-400 hover:text-black-700': isAssigned }" @click="tempIsPurchased == !tempIsPurchased"
>
        <input
@change="UpdatePurchaseStatus($event, gift.id, friend.GiftLink.items)" type="checkbox"
          :id="'purchased' + friend.id + gift.id" :value="gift.id" :checked="isPurchased" :disabled="!isAssigned"
          class="w-4 h-4 rounded text-green-600 bg-gray-100 border-gray-300 rounded focus:ring-green-500 focus:ring-2"
/>

        <label
:for="'purchased' + friend.id + gift.id" :value="gift.id"
          class="py-3 ms-2 text-xs sm:text-xs font-medium text-gray-900 dark:text-gray-300"
          :class="{ 'text-slate-200': !isAssigned }"
>Purchased</label>
      </div>
    </div>
  </div>
</template>

<script>
import { API } from "aws-amplify";

import {
  createGiftFriend,
  deleteGiftFriend,
  updateGiftFriend,
} from "../graphql/mutations";

export default {
  name: "FriendAssignItem",
  props: {
    friend: {
      type: Object,
      default() {
        return {};
      },
    },
    gift: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      giftImages: {},
      isPurchased: false,
      isAssigned: false,
      tempGiftLinkID: "",
    };
  },
  computed: {
    isAssignedAndNotPurchased() {
      return this.isAssigned && !this.isPurchased;
    },
    isAssignedAndPurchased() {
      return this.isAssigned && this.isPurchased;
    },
  },
  created() {
    this.getImage(this.gift);

    // Set purchased status
    this.friend.GiftLink.items.forEach((value) => {
      if (value.gift.id === this.gift.id) {
        if (value.purchased) {
          this.isPurchased = true;
        }
      }
    });

    // Set assigned status
    this.friend.GiftLink.items.forEach((value) => {
      if (value.gift.id === this.gift.id) {
        this.isAssigned = true;
      }
    });
  },
  methods: {
    getDomainName(url) {
      const urlObj = new URL(url);
      let domain = urlObj.hostname;

      if (domain.startsWith("www.")) {
        domain = domain.slice(4);
      }

      return domain;
    },
    getImageURL(giftId) {
      return this.giftImages[giftId];
    },
    getImage(gift) {
      const giftId = gift.id;
      const image = gift.image;
      if (!image) {
        this.giftImages[giftId] = require(`@/assets/gift.png`);
        return;
      }
      if (image?.includes("https://")) {
        this.giftImages[giftId] = image;
      } else {
        this.giftImages[giftId] =
          "https://" + process.env.VUE_APP_GIFT_IMAGE_BUCKET + "/public/" + image;
      }
    },
    async UpdateFriendLink(e, friendID, map) {
      if (e.target.checked) {
        try {
          const giftFriend = { giftId: e.target.value, friendId: friendID };
          const response = await API.graphql({
            query: createGiftFriend,
            variables: { input: giftFriend },
          });
          this.isAssigned = true;
          this.tempGiftLinkID = response.data.createGiftFriend.id;
        } catch (error) {
          console.log(error);
        }
      } else {
        try {
          var giftLinkId = "";
          map.forEach((value) => {
            if (value.gift.id === e.target.value) {
              giftLinkId = value.id;
            }
          });
          const mutationInput = { id: giftLinkId };
          const response = await API.graphql({
            query: deleteGiftFriend,
            variables: { input: mutationInput },
          });
        } catch (error) {
          console.log(error);
        }
        this.isAssigned = false;
        this.isPurchased = false;
      }
    },
    async UpdatePurchaseStatus(e, giftID, map) {
      var giftLinkId = "";
      if (this.tempGiftLinkID == "") {
        map.forEach((value) => {
          if (value.gift.id === giftID) {
            giftLinkId = value.id;
          }
        });
      } else {
        giftLinkId = this.tempGiftLinkID;
      }

      if (e.target.checked) {
        try {
          const purchaseStatus = { purchased: true, id: giftLinkId };
          const response = await API.graphql({
            query: updateGiftFriend,
            variables: { input: purchaseStatus },
          });
          this.isPurchased = true;
        } catch (error) {
          console.log(error);
        }
      } else {
        try {
          const purchaseStatus = { purchased: false, id: giftLinkId };
          const response = await API.graphql({
            query: updateGiftFriend,
            variables: { input: purchaseStatus },
          });
          this.isPurchased = false;
        } catch (error) {
          console.log(error);
        }
      }
    },
  },
};
</script>
