/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-southeast-2",
    "aws_appsync_graphqlEndpoint": "https://5tvswj54wzeonhdcwdnp7mfdaq.appsync-api.ap-southeast-2.amazonaws.com/graphql",
    "aws_appsync_region": "ap-southeast-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-hhmsjxktxjgjxjcpiurd6fiuwu",
    "aws_cognito_identity_pool_id": "ap-southeast-2:85a00f7d-d2bb-4ea6-9a26-5406bd791fba",
    "aws_cognito_region": "ap-southeast-2",
    "aws_user_pools_id": "ap-southeast-2_9YYf2E5Zf",
    "aws_user_pools_web_client_id": "7d9aiimph5ocgg825io0nqccl6",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "presentplanner220111126cf44ecf9440687412daae8f173123-prod",
    "aws_user_files_s3_bucket_region": "ap-southeast-2"
};


export default awsmobile;
