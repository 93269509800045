import { createStore } from "vuex";
import VuexPersistence from "vuex-persist";

export default createStore({
  state: {
    user: {
      loggedIn: false,
      data: null,
    },
    friendMap: [],
    friendSortedMap: [],
    giftMap: [],
    giftSort: "updatedAt",
  },
  getters: {
    user(state) {
      return state.user;
    },
    friendMap(state) {
      return state.friendMap;
    },
    friendSortedMap(state) {
      return state.friendSortedMap;
    },
    giftMap(state) {
      return state.giftMap;
    },
    giftSort(state) {
      return state.giftSort;
    },
  },
  mutations: {
    SET_LOGGED_IN(state, value) {
      state.user.loggedIn = value;
    },
    SET_USER(state, data) {
      state.user.data = data;
    },
    SET_GIFT_SORT(state, data) {
      state.giftSort = data;
    },
    SET_FRIEND_MAP(state, data) {
      state.friendMap = data;
    },
    SET_FRIEND_SORTED_MAP(state, data) {
      state.friendSortedMap = data;
    },
    SET_GIFT_MAP(state, data) {
      state.giftMap = data;
    },
    ADD_GIFT(state, data) {
      state.giftMap.push(data);
    },
    ADD_FRIEND(state, data) {
      state.friendMap.push(data);
    },
    UPDATE_GIFT(state, gift) {
      const giftIndex = state.giftMap.findIndex(function (item, index) {
        if (item.id == gift.id) {
          item = gift;
        };
      });

      state.giftMap[giftIndex] = gift;
    },
    DELETE_GIFT(state, gift) {
      var indexToDelete = null;
      state.giftMap.findIndex(function (item, index) {
        if (item.id == gift.id) indexToDelete = index;
      });
      if (indexToDelete !== null) {
        state.giftMap.splice(indexToDelete, 1);
      }
    },
    UPDATE_FRIEND(state, friend) {
      const friendIndex = state.friendMap.findIndex(function (item, index) {
        if (item.id == friend.id) return true;
      });

      state.friendMap[friendIndex] = friend;
    },
    DELETE_FRIEND(state, friend) {
      var indexToDelete = null;
      state.friendMap.findIndex(function (item, index) {
        if (item.id == friend.id) indexToDelete = index;
      });
      if (indexToDelete !== null) {
        state.friendMap.splice(indexToDelete, 1);
      }
    },
    RESET_STATE(state) {
      state.user.data = null;
      state.user.loggedIn = false;
      state.giftMap = [];
      state.friendMap = [];
    },
  },
  actions: {
    fetchUser({ commit }, user) {
      commit("SET_LOGGED_IN", user !== null);
      if (user) {
        commit("SET_USER", {
          username: user.attributes.email,
          userid: user.attributes.sub,
          plan: user.attributes["custom:plan"],
          country: user.attributes["custom:country"],
          show_mothers_day: user.attributes["custom:show_mothers_day"],
          show_fathers_day: user.attributes["custom:show_fathers_day"],
          remind_mothers_day: user.attributes["custom:remind_mothers_day"],
          remind_fathers_day: user.attributes["custom:remind_fathers_day"],
          remind_christmas: user.attributes["custom:remind_christmas"],
          remind_easter: user.attributes["custom:remind_easter"],
          remind_valentines: user.attributes["custom:remind_valentines"],
          
        });
      } else {
        commit("SET_LOGGED_IN", false);
        commit("SET_USER", null);
      }
    },

    setLoggedIn({ commit }, loggedin) {
      commit("SET_LOGGED_IN", loggedin);
    },

    setGiftSort({ commit }, giftSort) {
      commit("SET_GIFT_SORT", giftSort);
    },

    updateFriend({ commit }, friend) {
      commit("UPDATE_FRIEND", friend);
    },

    updateFriends({ commit }, friends) {
      commit("SET_FRIEND_MAP", friends);
    },

    updateFriendsSorted({ commit }, friends) {
      commit("SET_FRIEND_SORTED_MAP", friends);
    },

    updateGift({ commit }, gift) {
      commit("UPDATE_GIFT", gift);
    },

    updateGifts({ commit }, gifts) {
      commit("SET_GIFT_MAP", gifts);
    },

    addGift({ commit }, gift) {
      commit("ADD_GIFT", gift);
    },

    deleteGift({ commit }, gift) {
      commit("DELETE_GIFT", gift);
    },

    addFriend({ commit }, friend) {
      commit("ADD_FRIEND", friend);
    },
    resetState({ commit }) {
      commit("RESET_STATE");
    },
  },
  plugins: [new VuexPersistence().plugin],
});
