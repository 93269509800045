<template>
  <div class="border sm:w-full md:w-3/5 border-gray-300 rounded p-4 rounded-lg bg-slate-50">
    <div class="p-3 text-center">
      <div v-if="friends.length === 0">
        <p class="text-lg p-4 pb-8 text-gray-500 dark:text-gray-400">
          It doesn't look like you've added any friends or
          family yet :(
        </p>
        <button
@click="$router.push(`/people`)" type="button"
          class="text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-red-600 dark:focus:ring-red-800"
>
          Add people
        </button>
      </div>
      <ul class="flex flex-wrap w-full">
        <li v-for="friend in friends" :key="friend.id" :value="friend.id" class="p-1 w-full max-w-fit">
          <input
@change="UpdateFriendLink($event, gift.id, friend.GiftLink.items)" type="checkbox"
            :id="friend.id + gift.id" :value="friend.id" :checked="ContainsItem(friend.GiftLink.items, gift.id)"
            class="hidden peer" required=""
/>
          <label
:for="friend.id + gift.id"
            class="inline-flex items-center p-2 text-gray-500 bg-white border-2 border-gray-200 rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 hover:text-gray-600 dark:peer-checked:text-gray-300 hover:bg-gray-50 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700 peer-checked:border-red-600 peer-checked:font-bold peer-checked:text-gray-600"
>
            <div class="flex">
              <div class="w-full text-lg font-semibold self-center capitalize">{{ friend.name.toLowerCase() }}</div>
            </div>
          </label>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { API } from 'aws-amplify';

import { createGiftFriend, deleteGiftFriend } from '../graphql/mutations';

export default {
  name: "GiftAssignModal",
  props: {
    gift: {
      type: Object,
      default() {
        return {}
      }
    },
  },
  data() {
    return {
    }
  },
  computed: {
    friends() {
      return this.$store.state.friendMap;
    },
  },
  created() {
    this.getFriends();
  },
  methods: {
    async UpdateFriendLink(e, giftID, map) {
      if (e.target.checked) {
        try {
          const giftFriend = { friendId: e.target.value, giftId: giftID };
          const response = await API.graphql({ query: createGiftFriend, variables: { input: giftFriend }, });
        } catch (error) {
          console.log('Error creating gift...', error)
        }
      } else {
        try {
          var giftLinkId = "";
          map.forEach((value) => {
            if (value.gift.id === giftID) {
              giftLinkId = value.id;
            };
          });
          const mutationInput = { "id": giftLinkId };
          const response = await API.graphql({ query: deleteGiftFriend, variables: { input: mutationInput }, });
        } catch (error) {
          console.log('Error creating gift...', error)
        }
      }
    },
    ContainsItem(map, item) {
      let found = false;
      map.forEach((value) => {
        if (value.gift.id === item) {
          found = true;
        };
      });
      return found;
    },
    async getFriends() {
      const query = `query MyQuery {
  listFriends {
    items {
      id
      name
      email
      birthday
      reminder
      anniversary
      anniversaryReminder
      updatedAt
      GiftLink {
        items {
          id
          gift {
            name
            id
            price
            url
            image
            description
          }
        }
      }
    }
  }
}`
      let friends = await API.graphql({
        query: query
      });
      var sortedFriends = friends.data.listFriends.items.sort(
        function (a, b) {
          let nameA = a.name.toUpperCase();
          let nameB = b.name.toUpperCase();

          if (nameA < nameB) {
            return -1;
          }

          if (nameA > nameB) {
            return 1;
          }
        }
      );
      this.$store.dispatch("updateFriends", sortedFriends);
    },
  },
};
</script>