<template>
  <div class="border sm:w-full md:w4/5 lg:w-2/5 border-gray-300 rounded p-4 rounded-lg bg-slate-50">
    <div>
      <div class="mb-3">
        <label for="addGiftName" class="float-left block mb-2 text-sm font-medium text-gray-900 dark:text-white">Gift
          Name</label>
        <input
v-model="form.name" placeholder="A pot plant, a new pair of shoes, a new phone, etc."
          class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-red-500 focus:border-red-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-red-500 dark:focus:border-red-500"
          id="addGiftName" aria-describedby="emailHelp"
>
      </div>

      <div class="mb-3">
        <label
for="addGiftURL"
          class="float-left block mb-2 text-sm font-medium text-gray-900 dark:text-white"
>URL</label>
        <input
v-model="form.url" type="input" placeholder="https://www.amazon.com.au/dp/abcd1234/"
          class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-red-500 focus:border-red-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-red-500 dark:focus:border-red-500"
          id="addGiftURL"
>
      </div>

      <div class="mb-3">
        <label
for="addGiftDescription"
          class="float-left block mb-2 text-sm font-medium text-gray-900 dark:text-white"
>Description</label>
        <input
v-model="form.description" type="input" placeholder="The first dandelion of the season"
          class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-red-500 focus:border-red-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-red-500 dark:focus:border-red-500"
          id="addGiftDescription"
>
      </div>

      <div class="mb-3">
        <div class="flex">
          <label
for="addGiftPrice"
            class="float-left block mb-2 text-sm font-medium text-gray-900 dark:text-white"
>Price</label>
        </div>
        <div class="flex">
          <span
            class="inline-flex items-center px-3 text-md text-gray-900 bg-gray-200 border border-r-0 border-gray-300 rounded-l-md dark:bg-gray-600 dark:text-gray-400 dark:border-gray-600"
>
            $
          </span>
          <input
v-model="form.price" type="number" step="0.01" placeholder="15.00"
            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-red-500 focus:border-red-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-red-500 dark:focus:border-red-500"
            id="addGiftPrice"
>
        </div>
      </div>
    </div>

    <div class="flex items-center p-1 space-x-2 rounded-b border-t border-gray-200 dark:border-gray-600">
      <button
@click="createGift" type="button"
        class="text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm w-1/2 sm:w-auto sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-red-600 dark:focus:ring-red-800"
>
        Add
      </button>
      <button
@click="$router.go(-1)"
        class="text-gray-600 bg-white hover:bg-gray-100 rounded-lg border border-gray-200 text-sm w-1/2 sm:w-auto font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600"
>
        Cancel
      </button>
      <div class="flex items-center text-center">
        <p v-if="formError !== ''" class="pl-4 text-md text-red-600 dark:text-red-400">
          <span class="font-medium">Error: {{ formError }}</span>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { API } from 'aws-amplify';

import { createGift } from '../graphql/mutations';

export default {
  name: 'GiftAdd',
  data() {
    return {
      form: {
        name: '',
        description: '',
        price: '',
        url: null
      },
      formError: ''
    }
  },
  methods: {
    async createGift() {
      var image = ''

      var { name, description, price, url } = this.form
      if (!price) { price = 0.00 };
      if (!name) {
        this.formError = 'You need to at least set a name for the gift.'
        return
      } else {
        this.formError = ''
      };

      const gift = { name, description, price, url, image };
      try {
        const response = await API.graphql({ query: createGift, variables: { input: gift }, });

        this.$router.go(-1)
      } catch (error) {
        console.log('Error creating gift...', error)
      }
    },
  }
}
</script>