/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateFriend = /* GraphQL */ `
  subscription OnCreateFriend(
    $filter: ModelSubscriptionFriendFilterInput
    $owner: String
  ) {
    onCreateFriend(filter: $filter, owner: $owner) {
      birthday
      anniversary
      email
      id
      name
      GiftLink {
        items {
          id
          friendId
          giftId
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
      reminder
      anniversaryReminder
      lastReminder
      notifyRegistration
      owner
    }
  }
`;
export const onUpdateFriend = /* GraphQL */ `
  subscription OnUpdateFriend(
    $filter: ModelSubscriptionFriendFilterInput
    $owner: String
  ) {
    onUpdateFriend(filter: $filter, owner: $owner) {
      birthday
      anniversary
      email
      id
      name
      GiftLink {
        items {
          id
          friendId
          giftId
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
      reminder
      anniversaryReminder
      lastReminder
      notifyRegistration
      owner
    }
  }
`;
export const onDeleteFriend = /* GraphQL */ `
  subscription OnDeleteFriend(
    $filter: ModelSubscriptionFriendFilterInput
    $owner: String
  ) {
    onDeleteFriend(filter: $filter, owner: $owner) {
      birthday
      anniversary
      email
      id
      name
      GiftLink {
        items {
          id
          friendId
          giftId
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
      reminder
      anniversaryReminder
      lastReminder
      notifyRegistration
      owner
    }
  }
`;
export const onCreateGift = /* GraphQL */ `
  subscription OnCreateGift(
    $filter: ModelSubscriptionGiftFilterInput
    $owner: String
  ) {
    onCreateGift(filter: $filter, owner: $owner) {
      createdAt
      description
      id
      name
      price
      updatedAt
      FriendLink {
        items {
          id
          friendId
          giftId
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      url
      image
      owner
    }
  }
`;
export const onUpdateGift = /* GraphQL */ `
  subscription OnUpdateGift(
    $filter: ModelSubscriptionGiftFilterInput
    $owner: String
  ) {
    onUpdateGift(filter: $filter, owner: $owner) {
      createdAt
      description
      id
      name
      price
      updatedAt
      FriendLink {
        items {
          id
          friendId
          giftId
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      url
      image
      owner
    }
  }
`;
export const onDeleteGift = /* GraphQL */ `
  subscription OnDeleteGift(
    $filter: ModelSubscriptionGiftFilterInput
    $owner: String
  ) {
    onDeleteGift(filter: $filter, owner: $owner) {
      createdAt
      description
      id
      name
      price
      updatedAt
      FriendLink {
        items {
          id
          friendId
          giftId
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      url
      image
      owner
    }
  }
`;
export const onCreateGiftFriend = /* GraphQL */ `
  subscription OnCreateGiftFriend(
    $filter: ModelSubscriptionGiftFriendFilterInput
    $owner: String
  ) {
    onCreateGiftFriend(filter: $filter, owner: $owner) {
      id
      friendId
      giftId
      friend {
        birthday
        anniversary
        email
        id
        name
        GiftLink {
          nextToken
        }
        createdAt
        updatedAt
        reminder
        anniversaryReminder
        lastReminder
        notifyRegistration
        owner
      }
      gift {
        createdAt
        description
        id
        name
        price
        updatedAt
        FriendLink {
          nextToken
        }
        url
        image
        owner
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onUpdateGiftFriend = /* GraphQL */ `
  subscription OnUpdateGiftFriend(
    $filter: ModelSubscriptionGiftFriendFilterInput
    $owner: String
  ) {
    onUpdateGiftFriend(filter: $filter, owner: $owner) {
      id
      friendId
      giftId
      friend {
        birthday
        anniversary
        email
        id
        name
        GiftLink {
          nextToken
        }
        createdAt
        updatedAt
        reminder
        anniversaryReminder
        lastReminder
        notifyRegistration
        owner
      }
      gift {
        createdAt
        description
        id
        name
        price
        updatedAt
        FriendLink {
          nextToken
        }
        url
        image
        owner
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onDeleteGiftFriend = /* GraphQL */ `
  subscription OnDeleteGiftFriend(
    $filter: ModelSubscriptionGiftFriendFilterInput
    $owner: String
  ) {
    onDeleteGiftFriend(filter: $filter, owner: $owner) {
      id
      friendId
      giftId
      friend {
        birthday
        anniversary
        email
        id
        name
        GiftLink {
          nextToken
        }
        createdAt
        updatedAt
        reminder
        anniversaryReminder
        lastReminder
        notifyRegistration
        owner
      }
      gift {
        createdAt
        description
        id
        name
        price
        updatedAt
        FriendLink {
          nextToken
        }
        url
        image
        owner
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
