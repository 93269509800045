<template>
  <Header />
  <div class="py-2 bg-gradient-to-b from-blue-50 to-transparent w-full h-full lg:pb-2 relative">
    <h1
      class="p-1 mb-4 text-4xl font-extrabold leading-none tracking-tight text-gray-900 md:text-5xl lg:text-6xl dark:text-white text-center"
>
      Account
    </h1>

    <Account />
  </div>


  <Footer />
</template>

<script>
import Footer from "@/components/Footer.vue";
import Header from "@/components/Header.vue";
import Account from "@/components/Account.vue";
//import Debug from "@/components/Debug.vue";

export default {
  name: "AccountView",
  components: {
    Header,
    Footer,
    Account,
  //  Debug
  },
  created() { },
  computed: {},
  methods: {},
};
</script>
