<template>
  <Header />

  <div
    class="py-2 bg-gradient-to-b from-blue-50 to-transparent w-full h-full lg:pb-2 relative md:grid place-items-center"
>
    <div class="grid sm:flex sm:flex-row-reverse place-items-center pb-4 w-full md:w-2/5">
      <h1 class="sm:w-full text-4xl font-extrabold leading-none tracking-tight text-gray-900 md:text-4xl lg:text-5xl dark:text-white text-center">
        Add a new gift idea
      </h1>
      
      <button
type="button" @click="$router.go(-1)"
        class="mb-2 mt-2 sm:mt-4 w-auto sm:w-auto text-black hover:text-white border border-black hover:border-red-300 hover:bg-red-600 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2"
>
        Back
      </button>
    </div>

    <GiftAdd />

    <Footer />
  </div>

  <div class="p-5">
    <Debug />
  </div>
</template>

<script>
import Footer from "@/components/Footer.vue";
import Header from "@/components/Header.vue";
import GiftAdd from "@/components/GiftAdd.vue";
import Debug from "@/components/Debug.vue";

export default {
  name: "GiftView",
  components: {
    Header,
    Footer,
    GiftAdd,
    Debug,
  },
  created() {
    if (this.$store.state.user.data.plan !== 'paid' && this.$store.state.giftMap.length > 5) {
      this.$router.push('/gifts')
    };
  },
};
</script>
