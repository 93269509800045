<template>
    <div class="flex items-center justify-center">
        <table class="w-full sm:w-3/4 lg:w-1/2 mx-auto table-auto bg-white rounded-lg overflow-hidden">
            <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                <tr>
                    <th scope="col" class="px-2 sm:px-6 py-3 sm:w-6">
                    </th>
                    <th scope="col" class="sm:px-6 py-3 text-xs sm:text-sm">
                        Event
                    </th>
                    <th scope="col" class="sm:px-6 py-3 text-xs sm:text-sm">
                        Date
                    </th>
                    <th scope="col" class="sm:px-6 py-3 text-xs sm:text-sm">
                        Days until
                    </th>
                    <th scope="col" class="sm:px-6 py-3 text-xs sm:text-sm sm:w-6">
                        Reminders
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr
v-for="event in eventsSorted" :key="event.id"
                    class="bg-white border-b dark:bg-gray-900 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
>
                    <td class="px-2 sm:px-6 py-4 flex justify-center items-center">
                        <div v-if="event.event === 'birthday'" class="flex-shrink-0 h-4 w-4 sm:h-8 sm:w-8">
                            <svg
class="h-4 w-4 sm:h-8 sm:w-8 group-hover:text-red-600 dark:group-hover:text-red-600"
                                fill="none" stroke="currentColor" stroke-width="1.5" viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg" aria-hidden="true"
>
                                <path
stroke-linecap="round" stroke-linejoin="round"
                                    d="M12 8.25v-1.5m0 1.5c-1.355 0-2.697.056-4.024.166C6.845 8.51 6 9.473 6 10.608v2.513m6-4.87c1.355 0 2.697.055 4.024.165C17.155 8.51 18 9.473 18 10.608v2.513m-3-4.87v-1.5m-6 1.5v-1.5m12 9.75l-1.5.75a3.354 3.354 0 01-3 0 3.354 3.354 0 00-3 0 3.354 3.354 0 01-3 0 3.354 3.354 0 00-3 0 3.354 3.354 0 01-3 0L3 16.5m15-3.38a48.474 48.474 0 00-6-.37c-2.032 0-4.034.125-6 .37m12 0c.39.049.777.102 1.163.16 1.07.16 1.837 1.094 1.837 2.175v5.17c0 .62-.504 1.124-1.125 1.124H4.125A1.125 1.125 0 013 20.625v-5.17c0-1.08.768-2.014 1.837-2.174A47.78 47.78 0 016 13.12M12.265 3.11a.375.375 0 11-.53 0L12 2.845l.265.265zm-3 0a.375.375 0 11-.53 0L9 2.845l.265.265zm6 0a.375.375 0 11-.53 0L15 2.845l.265.265z"
>
                                </path>
                            </svg>
                        </div>
                        <div v-else-if="event.event === 'anniversary'" class="flex-shrink-0 h-4 w-4 sm:h-8 sm:w-8">
                            <svg
fill="none" stroke="currentcolor" stroke-width="1.5" viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg" aria-hidden="true"
>
                                <path
                                    d="M12.18 10.79C12.0694 10.7906 11.96 10.7666 11.8598 10.7199C11.7595 10.6733 11.6708 10.605 11.6 10.52L7 5C6.87702 4.8625 6.80902 4.68449 6.80902 4.5C6.80902 4.31552 6.87702 4.13751 7 4L8.82 2.14C8.89109 2.0661 8.97657 2.00757 9.07117 1.96801C9.16577 1.92845 9.26747 1.90871 9.37 1.91H14.89C15.0888 1.91018 15.2795 1.98931 15.42 2.13L17.23 3.93C17.3581 4.06233 17.4338 4.23667 17.443 4.42061C17.4521 4.60454 17.3942 4.78555 17.28 4.93L12.76 10.5C12.6917 10.5885 12.6037 10.6599 12.5031 10.7085C12.4024 10.7571 12.2918 10.7816 12.18 10.78V10.79ZM8.59 4.56L12.17 8.86L15.69 4.52L14.58 3.41H9.69L8.59 4.56Z"
                                    fill="#000000"
/>
                                <path
                                    d="M12.14 21.18C10.7295 21.1785 9.35111 20.7593 8.17862 19.9753C7.00613 19.1913 6.09212 18.0775 5.55191 16.7746C5.01171 15.4717 4.8695 14.038 5.14323 12.6543C5.41697 11.2707 6.09439 9.99908 7.09001 9C7.15868 8.92631 7.24148 8.86721 7.33348 8.82622C7.42548 8.78523 7.52479 8.76319 7.62549 8.76141C7.72619 8.75963 7.82622 8.77816 7.91961 8.81588C8.013 8.8536 8.09783 8.90974 8.16905 8.98096C8.24027 9.05218 8.29642 9.13701 8.33414 9.2304C8.37186 9.32379 8.39038 9.42382 8.38861 9.52452C8.38683 9.62523 8.36479 9.72454 8.3238 9.81654C8.2828 9.90854 8.2237 9.99134 8.15001 10.06C7.36462 10.8501 6.83082 11.8551 6.61593 12.9482C6.40104 14.0414 6.5147 15.1737 6.94257 16.2023C7.37044 17.2309 8.09335 18.1097 9.02011 18.728C9.94687 19.3462 11.036 19.6761 12.15 19.6761C13.2641 19.6761 14.3532 19.3462 15.2799 18.728C16.2067 18.1097 16.9296 17.2309 17.3575 16.2023C17.7853 15.1737 17.899 14.0414 17.6841 12.9482C17.4692 11.8551 16.9354 10.8501 16.15 10.06C16.0096 9.91937 15.9307 9.72875 15.9307 9.53C15.9307 9.33125 16.0096 9.14062 16.15 9C16.219 8.92924 16.3014 8.87301 16.3924 8.83461C16.4834 8.79621 16.5812 8.77643 16.68 8.77643C16.7788 8.77643 16.8766 8.79621 16.9676 8.83461C17.0587 8.87301 17.1411 8.92924 17.21 9C18.2091 9.99856 18.8895 11.271 19.1652 12.6564C19.4409 14.0417 19.2995 15.4777 18.7589 16.7827C18.2183 18.0877 17.3027 19.203 16.1281 19.9875C14.9535 20.772 13.5725 21.1905 12.16 21.19L12.14 21.18Z"
                                    fill="#000000"
/>
                            </svg>
                        </div>

                        <div v-else class="flex-shrink-0 h-4 w-4 sm:h-8 sm:w-8">
                            <svg
class="h-4 w-4 sm:h-8 sm:w-8 group-hover:text-red-600 dark:group-hover:text-red-600"
                                v-html="event.image"
></svg>
                        </div>
                    </td>
                    <th
scope="row"
                        class="sm:px-6 py-4 text-xs sm:text-sm font-medium text-gray-900 text-center whitespace-nowrap dark:text-white"
>
                        {{ event.name }}
                    </th>
                    <td class="sm:px-6 py-4 text-xs sm:text-sm text-center">
                        {{ event.date }}
                    </td>
                    <td
v-if="daysUntilNextAnniversary(event.date) < 1"
                        class="px-6 py-4 text-xs sm:text-sm text-center"
>
                        Today
                    </td>
                    <td
v-else-if="daysUntilNextAnniversary(event.date) < 2"
                        class="px-6 py-4 text-xs sm:text-sm text-center"
>
                        Tomorrow
                    </td>
                    <td v-else class="px-6 py-4 text-xs sm:text-sm text-center">
                        {{ daysUntilNextAnniversary(event.date) }}
                    </td>


                    <td class="sm:px-6 py-4 flex flex-col items-center justify-center">
                        <svg
v-if="!event.reminder" fill="none" class="h-4 w-4 sm:h-8 sm:w-8" stroke="currentColor"
                            stroke-width="1.5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"
                            aria-hidden="true"
>
                            <path
stroke-linecap="round" stroke-linejoin="round"
                                d="M9.143 17.082a24.248 24.248 0 003.844.148m-3.844-.148a23.856 23.856 0 01-5.455-1.31 8.964 8.964 0 002.3-5.542m3.155 6.852a3 3 0 005.667 1.97m1.965-2.277L21 21m-4.225-4.225a23.81 23.81 0 003.536-1.003A8.967 8.967 0 0118 9.75V9A6 6 0 006.53 6.53m10.245 10.245L6.53 6.53M3 3l3.53 3.53"
>
                            </path>
                        </svg>
                        <svg
v-else fill="none" class="h-4 w-4 sm:h-8 sm:w-8" stroke="currentColor" stroke-width="1.5"
                            viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true"
>
                            <path
stroke-linecap="round" stroke-linejoin="round"
                                d="M14.857 17.082a23.848 23.848 0 005.454-1.31A8.967 8.967 0 0118 9.75v-.7V9A6 6 0 006 9v.75a8.967 8.967 0 01-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 01-5.714 0m5.714 0a3 3 0 11-5.714 0"
>
                            </path>
                        </svg>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
import { API } from 'aws-amplify';


export default {
    name: 'UpcomingList',
    data() {
        return {
            predefinedEvents: [
                { name: "Mothers Day", event: "Mothers Day", date: this.calculateMothersDay(), show: (this.$store.state.user.data.show_mothers_day === 'true'), reminder: (this.$store.state.user.data.remind_mothers_day === 'true'), image: '<svg fill="none" stroke="currentcolor" stroke-width="2.5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true"><path stroke-linecap="round" stroke-linejoin="round" d="M9 18H15M12 13V21M12 13C14.7614 13 17 10.7614 17 8C17 5.23858 14.7614 3 12 3C9.23858 3 7 5.23858 7 8C7 10.7614 9.23858 13 12 13Z"></path></svg>' },
                { name: "Fathers Day", event: "Fathers Day", date: this.calculateFathersDay(), show: (this.$store.state.user.data.show_fathers_day === 'true'), reminder: (this.$store.state.user.data.remind_fathers_day === 'true'), image: '<svg fill="none" stroke="currentcolor" stroke-width="1.5" viewBox="0 2 12 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true"><path d="M17.56 8.060c0-0.44-0.36-0.88-0.84-0.88h-4.6c-0.48 0-0.84 0.36-0.84 0.84s0.36 0.84 0.84 0.84h2.6l-3.6 3.6c-1.16-0.92-2.64-1.48-4.24-1.48-3.76 0.080-6.88 3.16-6.88 6.96s3.12 6.88 6.88 6.88 6.88-3.080 6.88-6.88c0-1.6-0.56-3.040-1.48-4.24l3.6-3.6v2.76c0 0.48 0.36 0.84 0.84 0.84s0.84-0.36 0.84-0.84c0 0 0-4.8 0-4.8zM6.88 23.14c-2.88 0-5.2-2.32-5.2-5.2s2.32-5.2 5.2-5.2 5.2 2.32 5.2 5.2-2.32 5.2-5.2 5.2z"></path></svg>' },
                { name: "Christmas Day", event: "Christmas Day", date: '2020-12-25', reminder: (this.$store.state.user.data.remind_christmas === 'true'), image: '<svg fill="none" stroke="currentcolor" stroke-width="1.5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true"><path d="M5 14V11.3256C5 7.27977 8.27977 4 12.3256 4V4C15.666 4 18.5835 6.25985 19.4185 9.49427L20.0831 12.0684M16 11L17.6387 12.831M17.05 14C17.0172 14.1616 17 14.3288 17 14.5C17 15.7095 17.8589 16.7184 19 16.95M17.05 14H5C3.89543 14 3 14.8954 3 16V17C3 18.1046 3.89543 19 5 19H17C18.1046 19 19 18.1046 19 17V16.95M17.05 14C17.1401 13.5564 17.3476 13.1554 17.6387 12.831M17.6387 12.831C18.0964 12.3209 18.7607 12 19.5 12C19.7008 12 19.896 12.0237 20.0831 12.0684M20.0831 12.0684C21.1826 12.3311 22 13.3201 22 14.5C22 15.8807 20.8807 17 19.5 17C19.3288 17 19.1616 16.9828 19 16.95"/></svg>' },
                { name: "Valentines Day", event: "Valentines Day", date: '2020-02-14', reminder: (this.$store.state.user.data.remind_valentines === 'true'), image: '<svg fill="none" stroke="currentcolor" stroke-width="2.5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true"><path id="Vector" d="M19.2373 6.23731C20.7839 7.78395 20.8432 10.2727 19.3718 11.8911L11.9995 20.0001L4.62812 11.8911C3.15679 10.2727 3.21605 7.7839 4.76269 6.23726C6.48961 4.51034 9.33372 4.66814 10.8594 6.5752L12 8.00045L13.1396 6.57504C14.6653 4.66798 17.5104 4.51039 19.2373 6.23731Z" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>' },
                { name: "Easter Sunday", event: "Easter Sunday", date: this.getNextEasterSunday(), reminder: (this.$store.state.user.data.remind_easter === 'true'), image: '<svg fill="none" stroke="currentcolor" stroke-width="1.5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true"><path d="M3,13c0,3.263,2.2,10,9,10s9-6.737,9-10c0-6.5-5.348-12-9-12C8.255,1,3,6.61,3,13Zm9,8c-5.289,0-7-5.39-7-8,0-.308.022-.611.047-.914l2.364,1.722a1,1,0,0,0,1.3-.1L12,10.414l3.293,3.293a1,1,0,0,0,1.3.1l2.364-1.722c.025.3.047.606.047.914C19,15.61,17.289,21,12,21ZM11.989,3c2.786.061,5.715,4.164,6.548,6.915L16.1,11.688l-3.4-3.4a1,1,0,0,0-1.414,0l-3.4,3.4L5.463,9.915C6.3,7.159,9.216,3.091,11.989,3Z"/></svg>' },
            ],
            friendEvents: []
        }
    },
    components: {
    },
    computed: {
        friends() {
            return this.$store.state.friendSortedMap;
        },
        eventsSorted() {
            return this.sortEventsByDate()
        }
    },
    created() {
        this.getFriendEvents();
    },
    mounted() {

    },
    methods: {
        async getFriendEvents() {
            const query = `query MyQuery {
  listFriends(filter: {birthday: {ne: null, attributeExists: true}}) {
    items {
      name
      reminder
      birthday
      anniversary
      anniversaryReminder
    }
  }
}`

            await API.graphql({
                query: query
            }).then((friends) => {
                this.friendEvents.push(...friends.data.listFriends.items)
            })
        },


        calculateMothersDay() {
            const currentYear = new Date().getFullYear();
            const nextYear = currentYear + 1;
            const userCountry = this.$store.state.user.data.country;

            var septCountryList = ['Australia', "New Zealand", 'Brazil', 'USA', 'Canada', 'India', 'Fiji', 'Singapore', 'South Africa', 'Philippines', 'Netherlands'];
            var lentCountryList = ['UK', 'Ireland', 'Nigeria', 'Scotland', 'Wales']

            if (septCountryList.includes(userCountry)) {
                var mothersDays = [currentYear, nextYear].map(year => {
                    var date = new Date(year, 4, 1);

                    while (date.getDay() !== 0) {
                        date.setDate(date.getDate() + 1);
                    }

                    date.setDate(date.getDate() + 7);

                    return date;
                });
            } else if (lentCountryList.includes(userCountry)) {
                function easterSunday(Y) {
                    var C = Math.floor(Y / 100);
                    var N = Y - 19 * Math.floor(Y / 19);
                    var K = Math.floor((C - 17) / 25);
                    var I = C - Math.floor(C / 4) - Math.floor((C - K) / 3) + 19 * N + 15;
                    I = I - 30 * Math.floor((I / 30));
                    I = I - Math.floor(I / 28) * (1 - Math.floor(I / 28) * Math.floor(29 / (I + 1)) * Math.floor((21 - N) / 11));
                    var J = Y + Math.floor(Y / 4) + I + 2 - C + Math.floor(C / 4);
                    J = J - 7 * Math.floor(J / 7);
                    var L = I - J;
                    var M = 3 + Math.floor((L + 40) / 44);
                    var D = L + 28 - 31 * Math.floor(M / 4);

                    return new Date(Y, M - 1, D);
                }

                var mothersDays = [currentYear, nextYear].map(year => {
                    var easter = easterSunday(year);
                    var mothersDay = new Date(easter.getTime());
                    mothersDay.setDate(easter.getDate() - 21);
                    return mothersDay;
                });

            } else if (userCountry === 'France') {
                var mothersDays = [currentYear, nextYear].map(year => {
                    var date = new Date(year, 4, 31);

                    while (date.getDay() !== 0) {
                        date.setDate(date.getDate() - 1);
                    }
                    return date;
                });
            } else if (userCountry === 'Mexico') {
                var mothersDays = [currentYear, nextYear].map(year => {
                    var date = new Date(year, 4, 10);
                    return date;
                });
            } else {
                return null;
            }

            var [thisYear, nextYearMotherDay] = mothersDays;

            if (new Date() < thisYear) {
                const offset = thisYear.getTimezoneOffset()
                thisYear = new Date(thisYear.getTime() - (offset * 60 * 1000))
                return thisYear.toISOString().split('T')[0];
            } else {
                const offset = nextYearMotherDay.getTimezoneOffset()
                nextYearMotherDay = new Date(nextYearMotherDay.getTime() - (offset * 60 * 1000))
                return nextYearMotherDay.toISOString().split('T')[0];
            }
        },

        calculateFathersDay() {
            const currentYear = new Date().getFullYear();
            const nextYear = currentYear + 1;
            const userCountry = this.$store.state.user.data.country;

            console.log('Current Year:', currentYear);
            console.log('User Country:', userCountry);

            const septCountryList = ['Australia', 'New Zealand', 'Fiji'];
            const juneCountryList = ['USA', 'Canada', 'France', 'UK', 'India', 'Mexico', 'Singapore', 'South Africa', 'Philippines', 'Netherlands', 'Nigeria', 'Ireland', 'Scotland', 'Wales'];

            let fathersDays;

            if (septCountryList.includes(userCountry)) {
                // Australia, New Zealand, Fiji
                fathersDays = [currentYear, nextYear].map(year => {
                    const date = new Date(year, 8, 1); // September 1st (month is 0-indexed)
                    date.setDate(date.getDate() + (7 - date.getDay()) % 7); // Adjust to first Sunday
                    return date;
                });
            } else if (juneCountryList.includes(userCountry)) {
                fathersDays = [currentYear, nextYear].map(year => {
                    var date = new Date(year, 5, 1);

                    var count = 0;
                    while (true) {
                        if (date.getDay() === 0) {
                            count++;
                        }
                        if (count === 3) {
                            break;
                        }
                        date.setDate(date.getDate() + 1);
                    }
                    return date;
                });
            } else if (userCountry === 'Brazil') {
                fathersDays = [currentYear, nextYear].map(year => {
                    var date = new Date(year, 7, 1);

                    var count = 0;
                    while (true) {
                        if (date.getDay() === 0) {
                            count++;
                        }
                        if (count === 2) {
                            break;
                        }
                        date.setDate(date.getDate() + 1);
                    }
                    return date;
                });
            } else {
                return null;
            }

            console.log('Calculated Dates:', fathersDays.map(d => this.formatDateForLog(d)));

            const [thisYear, nextYearFatherDay] = fathersDays;

            const today = new Date();
            console.log('Today:', this.formatDateForLog(today));
            console.log('This Year Father\'s Day:', this.formatDateForLog(thisYear));
            console.log('Next Year Father\'s Day:', this.formatDateForLog(nextYearFatherDay));

            let result;
            if (this.compareLocalDates(today, thisYear) <= 0) {
                console.log('Returning this year\'s Father\'s Day');
                result = this.formatDate(thisYear);
            } else {
                console.log('Returning next year\'s Father\'s Day');
                result = this.formatDate(nextYearFatherDay);
            }

            console.log('Returned Result:', result);
            return result;
        },

        calculateEasterSunday(year) {
            const a = year % 19;
            const b = Math.floor(year / 100);
            const c = year % 100;
            const d = Math.floor(b / 4);
            const e = b % 4;
            const f = Math.floor((b + 8) / 25);
            const g = Math.floor((b - f + 1) / 3);
            const h = (19 * a + b - d - g + 15) % 30;
            const i = Math.floor(c / 4);
            const k = c % 4;
            const l = (32 + 2 * e + 2 * i - h - k) % 7;
            const m = Math.floor((a + 11 * h + 22 * l) / 451);
            const month = Math.floor((h + l - 7 * m + 114) / 31) - 1;
            const day = ((h + l - 7 * m + 114) % 31) + 1;
            const date = new Date(year, month, day);
            return date;
        },

        getNextEasterSunday() {
            const currentYear = new Date().getFullYear();
            const nextYear = currentYear + 1;

            var easterSundays = [currentYear, nextYear].map(year => this.calculateEasterSunday(year));

            var [thisYear, nextYearEaster] = easterSundays;

            if (new Date() < thisYear) {
                const offset = thisYear.getTimezoneOffset()
                thisYear = new Date(thisYear.getTime() - (offset * 60 * 1000))
                return thisYear.toISOString().split('T')[0];
            } else {
                const offset = nextYearEaster.getTimezoneOffset()
                nextYearEaster = new Date(nextYearEaster.getTime() - (offset * 60 * 1000))
                return nextYearEaster.toISOString().split('T')[0];
            }
        },

        sortEventsByDate() {
            const today = new Date();
            today.setHours(0, 0, 0, 0);

            var combinedEvents = [...this.friendEvents, ...this.predefinedEvents];

            var sortedEvents = combinedEvents
                .flatMap(friend => [
                    friend.birthday ? { ...friend, event: 'birthday', date: friend.birthday, reminder: friend.reminder } : null,
                    friend.anniversary ? { ...friend, event: 'anniversary', date: friend.anniversary, reminder: friend.anniversaryReminder } : null,
                    friend.date ? { ...friend, event: friend.event, date: friend.date, reminder: friend.reminder } : null
                ])
                .filter(Boolean)
                .sort(function (a, b) {
                    var eventDateA = new Date(a.date);
                    let nextDateA = new Date(today.getFullYear(), eventDateA.getMonth(), eventDateA.getDate());
                    if (today > nextDateA) {
                        nextDateA.setFullYear(nextDateA.getFullYear() + 1);
                    }
                    var diffMillisA = nextDateA.getTime() - today.getTime();
                    var daysUntilA = Math.ceil(diffMillisA / (1000 * 60 * 60 * 24));

                    var eventDateB = new Date(b.date);
                    let nextDateB = new Date(today.getFullYear(), eventDateB.getMonth(), eventDateB.getDate());
                    if (today > nextDateB) {
                        nextDateB.setFullYear(nextDateB.getFullYear() + 1);
                    }
                    var diffMillisB = nextDateB.getTime() - today.getTime();
                    var daysUntilB = Math.ceil(diffMillisB / (1000 * 60 * 60 * 24));

                    var offsetA = nextDateA.getTimezoneOffset()
                    nextDateA = new Date(nextDateA.getTime() - (offsetA * 60 * 1000))
                    a.date = nextDateA.toISOString().split('T')[0];

                    var offsetB = nextDateB.getTimezoneOffset()
                    nextDateB = new Date(nextDateB.getTime() - (offsetB * 60 * 1000))
                    b.date = nextDateB.toISOString().split('T')[0];

                    return daysUntilA - daysUntilB;
                });


            return sortedEvents.filter(obj => obj.show !== false);;
        },

        daysUntilNextAnniversary(dob) {
            const today = new Date();
            today.setHours(0, 0, 0, 0);
            const eventDate = new Date(dob);

            let nexteventDate = new Date(today.getFullYear(), eventDate.getMonth(), eventDate.getDate());

            if (nexteventDate === today) {
                return 0;
            };

            if (today > nexteventDate) {
                nexteventDate.setFullYear(nexteventDate.getFullYear() + 1);
            }

            const diffMillis = nexteventDate.getTime() - today.getTime();

            return Math.ceil(diffMillis / (1000 * 60 * 60 * 24));
        },

        formatDate(date) {
            const options = { timeZone: 'Australia/Sydney', year: 'numeric', month: '2-digit', day: '2-digit' };
            const [month, day, year] = date.toLocaleDateString('en-US', options).split('/');
            return `${year}-${month}-${day}`;
        },

        formatDateForLog(date) {
            return date.toLocaleString('en-US', { timeZone: 'Australia/Sydney' });
        },

        compareLocalDates(date1, date2) {
            const d1 = new Date(date1.toLocaleDateString('en-US', { timeZone: 'Australia/Sydney' }));
            const d2 = new Date(date2.toLocaleDateString('en-US', { timeZone: 'Australia/Sydney' }));
            return d1 - d2;
        }

    }
}
</script>