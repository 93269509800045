<template>
  <div class="flex flex-wrap gap-2 sm:gap-4 p-4 self-center place-content-center place-items-center">
    <div
v-for="gift in gifts" :key="gift.id"
      class="h-fit w-28 sm:w-56 border border-gray-300 rounded rounded-lg bg-white"
>
      <GiftItem :gift="gift"></GiftItem>
    </div>
    <div v-if="gifts.length === 0">
      <p class="text-graSy-500 text-xl text-center">Get started by adding a gift using the button above</p>
    </div>
  </div>
</template>

<script>
import { API } from "aws-amplify";

import { listGifts } from "../graphql/queries";

import { onCreateGift, onDeleteGift } from "../graphql/subscriptions";

import GiftItem from "@/components/GiftItem.vue";

export default {
  name: "GiftListView",
  components: {
    GiftItem,
  },
  props: {
    sortby: {
      type: String,
    },
  },
  data() {
    return {
      giftCreateSub: null,
      giftDeleteSub: null,
    };
  },
  created() {
    this.getGifts(this.sortby);
    if (this.$store.state.user.loggedIn) {
      this.subscribe();
    };
  },
  watch: {
    'sortby': function (newSort, oldSort) {
      this.$store.dispatch("setGiftSort", newSort);
      this.getGifts(newSort);
    },
  },
  mounted() {
  },
  unmounted() {
    this.giftCreateSub.unsubscribe();
    this.giftDeleteSub.unsubscribe();
  },
  setup() { },
  computed: {
    gifts() {
      return this.$store.state.giftMap;
    },
  },
  methods: {
    async getGifts(sortBy) {
      const gifts = await API.graphql({
        variables: {
          limit: 100,
        },
        query: listGifts,
      });
      var sortedGifts = gifts.data.listGifts.items.sort(
        function (a, b) {
          if (sortBy === "name") {
            return a[sortBy].localeCompare(b[sortBy]);
          }
          else if (sortBy === "price") {
            return b[sortBy] - a[sortBy];
          }
          else {
            return new Date(b[sortBy]) - new Date(a[sortBy]);
          }
        }
      );
      this.$store.dispatch("updateGifts", sortedGifts);
    },

    subscribe() {
      this.giftCreateSub = API.graphql({
        query: onCreateGift,
        variables: { owner: this.$store.state.user.data.userid },
      }).subscribe({
        next: (eventData) => {
          let gift = eventData.value.data.onCreateGift;
          this.$store.dispatch("addGift", gift);
        },
      });

      this.giftDeleteSub = API.graphql({
        query: onDeleteGift,
        variables: { owner: this.$store.state.user.data.userid },
      }).subscribe({
        next: (eventData) => {
          let gift = eventData.value.data.onDeleteGift;
          this.$store.dispatch("deleteGift", gift);
        },
      });
    },
  },
};
</script>
