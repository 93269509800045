<template>
  <Header />

  <div
    class="py-2 bg-gradient-to-b from-blue-50 to-transparent w-full h-full lg:pb-2 relative md:grid place-items-center"
>
    <div class="grid sm:flex sm:flex-row-reverse place-items-center pb-4 w-full md:w-2/5">
      <h1 class="sm:w-full text-4xl font-extrabold leading-none tracking-tight text-gray-900 md:text-4xl lg:text-5xl dark:text-white text-center">
        Editing <span class="text-red-400">{{ gift.name }}</span>
      </h1>
      
      <button
type="button" @click="$router.go(-1)"
        class="mb-2 mt-2 sm:mt-4 w-auto sm:w-auto text-black hover:text-white border border-black hover:border-red-300 hover:bg-red-600 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2"
>
        Back
      </button>
    </div>

    <GiftEdit :gift="gift" />

    <Footer />
  </div>
</template>

<script>
import Footer from "@/components/Footer.vue";
import Header from "@/components/Header.vue";
import GiftEdit from "@/components/GiftEdit.vue";
//import Debug from "@/components/Debug.vue";

export default {
  name: "GiftEditView",
  components: {
    Header,
    Footer,
    GiftEdit,
    //Debug,
  },
  created() {
    if (!this.$store.state.giftMap.find(item => item.id === this.$route.params.id)) {
      this.$router.push({ name: 'Gifts' })
    }
  },
  data() {
    return {
      gift: this.$store.state.giftMap.find(item => item.id === this.$route.params.id),
    }
  },
};
</script>
