/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createFriend = /* GraphQL */ `
  mutation CreateFriend(
    $input: CreateFriendInput!
    $condition: ModelFriendConditionInput
  ) {
    createFriend(input: $input, condition: $condition) {
      birthday
      anniversary
      email
      id
      name
      GiftLink {
        items {
          id
          friendId
          giftId
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
      reminder
      anniversaryReminder
      lastReminder
      notifyRegistration
      owner
    }
  }
`;
export const updateFriend = /* GraphQL */ `
  mutation UpdateFriend(
    $input: UpdateFriendInput!
    $condition: ModelFriendConditionInput
  ) {
    updateFriend(input: $input, condition: $condition) {
      birthday
      anniversary
      email
      id
      name
      GiftLink {
        items {
          id
          friendId
          giftId
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
      reminder
      anniversaryReminder
      lastReminder
      notifyRegistration
      owner
    }
  }
`;
export const deleteFriend = /* GraphQL */ `
  mutation DeleteFriend(
    $input: DeleteFriendInput!
    $condition: ModelFriendConditionInput
  ) {
    deleteFriend(input: $input, condition: $condition) {
      birthday
      anniversary
      email
      id
      name
      GiftLink {
        items {
          id
          friendId
          giftId
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
      reminder
      anniversaryReminder
      lastReminder
      notifyRegistration
      owner
    }
  }
`;
export const createGift = /* GraphQL */ `
  mutation CreateGift(
    $input: CreateGiftInput!
    $condition: ModelGiftConditionInput
  ) {
    createGift(input: $input, condition: $condition) {
      createdAt
      description
      id
      name
      price
      updatedAt
      FriendLink {
        items {
          id
          friendId
          giftId
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      url
      image
      owner
    }
  }
`;
export const updateGift = /* GraphQL */ `
  mutation UpdateGift(
    $input: UpdateGiftInput!
    $condition: ModelGiftConditionInput
  ) {
    updateGift(input: $input, condition: $condition) {
      createdAt
      description
      id
      name
      price
      updatedAt
      FriendLink {
        items {
          id
          friendId
          giftId
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      url
      image
      owner
    }
  }
`;
export const deleteGift = /* GraphQL */ `
  mutation DeleteGift(
    $input: DeleteGiftInput!
    $condition: ModelGiftConditionInput
  ) {
    deleteGift(input: $input, condition: $condition) {
      createdAt
      description
      id
      name
      price
      updatedAt
      FriendLink {
        items {
          id
          friendId
          giftId
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      url
      image
      owner
    }
  }
`;
export const createGiftFriend = /* GraphQL */ `
  mutation CreateGiftFriend(
    $input: CreateGiftFriendInput!
    $condition: ModelGiftFriendConditionInput
  ) {
    createGiftFriend(input: $input, condition: $condition) {
      id
      friendId
      giftId
      friend {
        birthday
        anniversary
        email
        id
        name
        GiftLink {
          nextToken
        }
        createdAt
        updatedAt
        reminder
        anniversaryReminder
        lastReminder
        notifyRegistration
        owner
      }
      gift {
        createdAt
        description
        id
        name
        price
        updatedAt
        FriendLink {
          nextToken
        }
        url
        image
        owner
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateGiftFriend = /* GraphQL */ `
  mutation UpdateGiftFriend(
    $input: UpdateGiftFriendInput!
    $condition: ModelGiftFriendConditionInput
  ) {
    updateGiftFriend(input: $input, condition: $condition) {
      id
      friendId
      giftId
      friend {
        birthday
        anniversary
        email
        id
        name
        GiftLink {
          nextToken
        }
        createdAt
        updatedAt
        reminder
        anniversaryReminder
        lastReminder
        notifyRegistration
        owner
      }
      gift {
        createdAt
        description
        id
        name
        price
        updatedAt
        FriendLink {
          nextToken
        }
        url
        image
        owner
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteGiftFriend = /* GraphQL */ `
  mutation DeleteGiftFriend(
    $input: DeleteGiftFriendInput!
    $condition: ModelGiftFriendConditionInput
  ) {
    deleteGiftFriend(input: $input, condition: $condition) {
      id
      friendId
      giftId
      friend {
        birthday
        anniversary
        email
        id
        name
        GiftLink {
          nextToken
        }
        createdAt
        updatedAt
        reminder
        anniversaryReminder
        lastReminder
        notifyRegistration
        owner
      }
      gift {
        createdAt
        description
        id
        name
        price
        updatedAt
        FriendLink {
          nextToken
        }
        url
        image
        owner
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
