<template>
  <form @submit.prevent class="border sm:w-full md:w-2/5 border-gray-300 rounded p-4 rounded-lg bg-slate-50">
    <div class="mb-3">
      <label for="giftName" class="float-left block mb-2 text-sm font-medium text-gray-900 dark:text-white">Name</label>
      <input
v-model="giftForm.name"
        class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-red-500 focus:border-red-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-red-500 dark:focus:border-red-500"
        id="giftName"
>
    </div>

    <div class="mb-3">
      <label for="giftUrl" class="float-left block mb-2 text-sm font-medium text-gray-900 dark:text-white">URL</label>
      <input
v-model="giftForm.url" type="input"
        class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-red-500 focus:border-red-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-red-500 dark:focus:border-red-500"
        id="giftUrl"
>
    </div>

    <div class="mb-3">
      <label
for="giftDescription"
        class="float-left block mb-2 text-sm font-medium text-gray-900 dark:text-white"
>Description</label>
      <input
v-model="giftForm.description" type="input"
        class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-red-500 focus:border-red-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-red-500 dark:focus:border-red-500"
        id="giftDescription"
>
    </div>

    <div class="mb-3">
      <div class="flex">
        <label
for="giftPrice"
          class="float-left block mb-2 text-sm font-medium text-gray-900 dark:text-white"
>Price</label>
      </div>
      <div class="flex">
        <span
          class="inline-flex items-center px-3 text-sm text-gray-900 bg-gray-200 border border-r-0 border-gray-300 rounded-l-md dark:bg-gray-600 dark:text-gray-400 dark:border-gray-600"
>
          $
        </span>
        <input
v-model="giftForm.price" type="number" step="0.01"
          class="rounded-none rounded-r-lg bg-gray-50 border text-gray-900 focus:ring-blue-500 focus:border-blue-500 block flex-1 min-w-0 w-full text-sm border-gray-300 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          id="giftPrice"
>
      </div>
    </div>

    <div class="mb-0">
      <div class="flex">
        <label class="block mb-2 text-sm font-medium text-gray-900 dark:text-white" for="file_input">Upload file</label>
      </div>
      <input
        class="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
        @change="UpdateGiftImage()" id="giftImage" ref="giftImage" type="file"
>
    </div>
    <div class="flex mb-2">
      <p class="float-left mb-1 fw-light">
        We'll try and grab an image for you, but if we can't, you can upload your
        own
      </p>
    </div>

    <div class="flex items-center p-1 space-x-2 rounded-b border-t border-gray-200 dark:border-gray-600">
      <button
@click="UpdateGift" type="button"
        class="text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm w-1/2 sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-red-600 dark:focus:ring-red-800"
>
        Save
      </button>
      <button
@click="$router.go(-1)" type="button"
        class="text-gray-600 bg-white hover:bg-gray-100 rounded-lg border border-gray-200 text-sm w-1/2 sm:w-auto font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600"
>
        Cancel
      </button>
    </div>
  </form>
</template>

<script>
import { API, Storage } from 'aws-amplify';

import { updateGift } from '../graphql/mutations';

export default {
  name: "GiftEdit",
  props: {
    gift: {
      type: Object,
      default() {
        return {}
      }
    },
  },
  components: {
  },
  computed: {
  },
  data() {
    return {
      giftForm: {},
      giftImage: this.gift.image,
    };
  },
  created() {
    this.giftForm = { ...this.gift }
  },
  watch: {
    gift(newGift) {
      if (Object.keys(this.gift).length === 0 && this.item.constructor === Object) {
        this.giftForm = newGift
      }
    },
  },
  methods: {
    removeImage() {
      this.giftImage = null;
    },
    async UpdateGiftImage() {
      const result = await Storage.put(
        this.gift.id + '.' + this.$refs.giftImage.files[0].name.split('.')[this.$refs.giftImage.files[0].name.split('.').length - 1],
        this.$refs.giftImage.files[0],
        { level: "public" });
      this.giftImage = result.key;
    },
    async UpdateGift() {
      if (this.giftForm.url != "" && this.giftForm.url != null) {
        if (!this.giftForm.url.includes("http")) {
          var url = 'https://' + this.giftForm.url;
        } else {
          var url = this.giftForm.url;
        }
      } else {
        var url = null;
      };

      if (this.giftImage.includes("gift.png") && this.giftForm.name != this.gift.name) {
        var giftImage = ''
      } else {
        var giftImage = this.giftImage;
      }
      const giftData = {
        id: this.gift.id,
        name: this.giftForm.name,
        description: this.giftForm.description,
        price: this.giftForm.price,
        url: url,
        image: giftImage,
      };
      try {
        const response = await API.graphql({ query: updateGift, variables: { input: giftData } });
        this.$router.go(-1)
      } catch (error) {
        console.log('Error creating gift...')
        console.log(error)
      }
    },
  },
};
</script>