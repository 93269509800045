<template>
  <div class="container-l">
    <Header></Header>
    <div class="py-2 bg-gradient-to-b from-blue-50 to-transparent w-full h-full lg:pb-2 relative">
      <h1
v-if="showLogin"
        class="p-1 mb-4 text-4xl font-extrabold leading-none tracking-tight text-gray-900 md:text-5xl lg:text-6xl dark:text-white text-center"
>
        Welcome back
      </h1>
      <h1
v-else
        class="p-1 mb-4 text-4xl font-extrabold leading-none tracking-tight text-gray-900 md:text-5xl lg:text-6xl dark:text-white text-center"
>
        Welcome aboard
      </h1>
      <div class="md:grid place-items-center">
        <div class="sm:w-full md:w-1/4 border border-gray-300 rounded p-4 rounded-lg bg-slate-50">
          <ul
            class="text-sm font-medium text-center text-gray-500 rounded-lg sm:flex dark:divide-gray-700 dark:text-gray-400 mb-8"
>
            <li class="sm:w-1/3 mr-1 md:w-full">
              <a
href="/login"
                :class="[showLogin ? ['inline-block', 'rounded-lg', 'w-full', 'p-4', 'text-white', 'bg-red-600', 'hover:bg-red-700', 'focus:ring-4', 'focus:outline-none', 'focus:ring-red-300', 'font-medium', 'w-full', 'text-center'] : ['inline-block', 'rounded-lg', 'w-full', 'p-4', 'bg-gray-200', 'hover:text-gray-700', 'hover:bg-gray-300', 'focus:ring-4', 'focus:ring-red-300', 'focus:outline-none', 'dark:hover:text-white', 'dark:bg-gray-800', 'dark:hover:bg-gray-700']]"
>Login</a>
            </li>
            <li class="sm:w-1/3 ml-1 md:w-full">
              <a
href="/register"
                :class="[showLogin ? ['inline-block', 'rounded-lg', 'w-full', 'p-4', 'bg-gray-200', 'hover:text-gray-700', 'hover:bg-gray-300', 'focus:ring-4', 'focus:ring-red-300', 'focus:outline-none', 'dark:hover:text-white', 'dark:bg-gray-800', 'dark:hover:bg-gray-700'] : ['inline-block', 'rounded-lg', 'w-full', 'p-4', 'text-white', 'bg-red-600', 'hover:bg-red-700', 'focus:ring-4', 'focus:outline-none', 'focus:ring-red-300', 'font-medium', 'w-full', 'text-center']]"
                class=""
>Register</a>
            </li>
          </ul>
          <form @submit.prevent v-if="showLogin">
            <div class="mb-6">
              <label for="email" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Email</label>
              <input
v-model="loginForm.username" type="email" id="email"
                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-red-500 focus:border-red-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-red-500 dark:focus:border-red-500"
                placeholder="johncena@gmail.com" required
>
            </div>
            <div class="mb-6">
              <label
for="password"
                class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
>Password</label>
              <input
v-model="loginForm.password" type="password" id="password"
                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-red-500 focus:border-red-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-red-500 dark:focus:border-red-500"
                required
>
            </div>
            <button
@click="signIn" type="submit"
              class="text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-red-600 dark:focus:ring-red-800"
>
              Login
            </button>
            <p v-if="signInErrorMessage" id="filled_error_help" class="mt-2 text-xs text-red-600 dark:text-red-400">
              <span class="font-medium">Oh, no!</span> {{ signInErrorMessage }}
            </p>
          </form>

          <form @submit.prevent v-else>
            <div class="mb-6">
              <label for="email" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Email</label>
              <input
v-model="registerForm.username" type="email" id="email" :disabled="userVerification"
                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-red-500 focus:border-red-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-red-500 dark:focus:border-red-500"
                placeholder="johncena@gmail.com" required
>
            </div>
            <div class="mb-6">
              <label
for="password"
                class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
>Password</label>
              <input
v-model="registerForm.password" type="password" id="password" :disabled="userVerification"
                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-red-500 focus:border-red-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-red-500 dark:focus:border-red-500"
                required
>
            </div>
            <button
@click="signUp" type="submit" :disabled="userVerification"
              :class="[userVerification ? ['plausible-event-name=SignUp', 'text-white', 'bg-gray-300', 'font-medium', 'rounded-lg', 'text-sm', 'w-full', 'sm:w-auto', 'px-5', 'py-2.5', 'text-center', 'dark:bg-gray-300'] : ['plausible-event-name=SignUp', 'text-white', 'bg-red-600', 'hover:bg-red-800', 'focus:ring-4', 'focus:outline-none', 'focus:ring-red-300', 'font-medium', 'rounded-lg', 'text-sm', 'w-full', 'sm:w-auto', 'px-5', 'py-2.5', 'text-center', 'dark:bg-grey-600']]"
>
              Register
            </button>
            <p
v-if="signUpErrorMessage !== ''" id="filled_error_help"
              class="mt-2 text-xs text-red-600 dark:text-red-400"
>
              <span class="font-medium">Oh, no!
              </span>{{ signUpErrorMessage }}
            </p>
          </form>

          <form @submit.prevent v-if="userVerification">
            <p class="my-2 text-md text-blue-600 dark:text-blue-400">
Please check your email for a verification code.
            </p>
            <div class="mb-6">
              <label for="password" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Verification
                code</label>
              <input
v-model="userVerificationCode" type="text" id="userVerificationCode"
                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-red-500 focus:border-red-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-red-500 dark:focus:border-red-500"
                required
>
            </div>
            <button
@click="verifyNewUser" type="submit"
              class="text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-red-600 dark:focus:ring-red-800"
>
              Verify
            </button>
            <p
v-if="userVerificationError !== ''" id="filled_error_help"
              class="mt-2 text-xs text-red-600 dark:text-red-400"
>
              <span class="font-medium">Oh, no!
              </span>{{ userVerificationError }}
            </p>
          </form>
        </div>
      </div>
    </div>

    <div class="p-5">
      <Debug />
    </div>
  </div>
</template>

<script>

import Header from "@/components/Header.vue";
import Debug from "@/components/Debug.vue";

import { Auth, Hub } from 'aws-amplify';

import store from "../store";

import { event } from 'vue-gtag'

export default {
  name: "AuthView",
  components: {
    Header,
    Debug
  },

  data() {
    return {
      loginForm: {
        username: '',
        password: '',
      },
      registerForm: {
        username: '',
        password: '',
      },
      newPasswordForm: {
        username: '',
        newpassword1: '',
        newpassword2: ''
      },
      passwordChangeRequired: null,
      userVerification: null,
      userVerificationCode: "",
      userVerificationError: "",
      user: null,
      registerEmail: "",
      signUpErrorMessage: "",
      signInErrorMessage: "",
      countryName: "Australia"
    }
  },

  computed: {
    isLoggedIn() {
      return this.$store.state.user.loggedIn;
    },
    showLogin() {
      if (this.$route.name === "Login") {
        return true;
      } else {
        return false;
      }
    }
  },
  methods: {
    registerTab() {
      this.showLogin = false
    },
    loginTab() {
      console.log("Login!")
      this.signUpErrorMessage = "";
      this.signInErrorMessage = "";
      this.userVerificationError = "";
      this.showLogin = true
    },
    signIn() {
      const { username, password } = this.loginForm;
      Auth.signIn(username, password)
        .then(user => {
          if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
            this.passwordChangeRequired = true;
            this.newPasswordForm.username = username;
            this.user = user;
          } else {
            this.$store.dispatch("fetchUser", user);
            this.$router.push(`/gifts`);
          };
        })
        .catch((error) => {
          this.signInErrorMessage = error.message;
        })
    },

    setNewPassword() {
      const { username, newpassword1, newpassword2 } = this.newPasswordForm;
      // ADD LOGIC TO CONFIRM THE PASSWORDS MATCH
      Auth.completeNewPassword(
        this.user,
        newpassword1,
      )
        .then((user) => {
          this.$store.dispatch("fetchUser", user);
          $('#authModal').hide();
          $('.modal-backdrop').hide();
        })
        .catch((e) => {
          console.log(e);
        });
    },

    verifyNewUser() {
      const { username, password } = this.registerForm;
      const userVerificationCode = this.userVerificationCode;
      Auth.confirmSignUp(
        username,
        userVerificationCode,
      )
        .then((user) => {
          Auth.signIn(username, password)
            .then(loggedInUser => {
              plausible('SignUp')
              event('sign_up', { method: 'Google' })
              console.log(loggedInUser);
              this.$store.dispatch("fetchUser", loggedInUser);
              this.$router.push(`/gifts`);
            })
        })
        .catch((error) => {
          this.userVerificationError = error.message;
          console.log(error);
        });
    },

    signOut() {
      Auth.signOut()
        .then(user => {
          store.dispatch("fetchUser");
        })
        .catch((error) => console.log(`Error: ${error}`))
    },

    signUp() {
      const { username, password } = this.registerForm;
      Auth.signUp({
        username,
        password,
        attributes: {
          'custom:country': this.countryName,
          'custom:show_mothers_day': 'true',
          'custom:show_fathers_day': 'true',
          'custom:remind_mothers_day': 'true',
          'custom:remind_fathers_day': 'true',
          'custom:remind_christmas': 'true',
          'custom:remind_easter': 'true',
          'custom:remind_valentines': 'true',
          'custom:plan': 'free',
        }
      }).then((user) => {
        if (user.userConfirmed === false) {
          this.user = user;
          this.userVerification = true;
        };
        this.signUpErrorMessage = "";
        this.signInErrorMessage = "";
        this.userVerificationError = "";
      }).catch((error) => {
        if (error.code === "UsernameExistsException") {
          this.signUpErrorMessage = "This email is already registered. Please log in.";
        } else {
          this.signUpErrorMessage = error.message;
        }
        console.log(`Error: ${error.message}`);
      });
    },
    expandCountryCode(code) {
      switch (code) {
        case 'AU':
          return 'Australia';
        case 'BR':
          return 'Brazil';
        case 'CA':
          return 'Canada';
        case 'FJ':
          return 'Fiji';
        case 'FR':
          return 'France';
        case 'IN':
          return 'India';
        case 'IE':
          return 'Ireland';
        case 'MX':
          return 'Mexico';
        case 'NL':
          return 'Netherlands';
        case 'NZ':
          return 'New Zealand';
        case 'PH':
          return 'Philippines';
        case 'SC':
          return 'Scotland';
        case 'SG':
          return 'Singapore';
        case 'ZA':
          return 'South Africa';
        case 'GB':
          return 'UK';
        case 'US':
          return 'USA';
        case 'WA':
          return 'Wales';
        default:
          return null;
      }
    },
  },
  mounted() {
    Hub.listen('auth', ({ payload }) => {
      const { event } = payload;
      if (event === 'autoSignIn') {
        const user = payload.data;
        this.$store.dispatch("fetchUser", user);
        this.$router.push(`/`);
      } else if (event === 'autoSignIn_failure') {
        this.$router.push(`/login`);
      }
    });
  },
  created() {
    if (this.$route.params.email) {
      this.registerEmail = this.$route.params.email;
      this.registerForm.username = this.$route.params.email;
    }

    if (this.$route.query.country) {
      const normalizedQueryParam = this.$route.query.country.trim().toUpperCase();

      if (normalizedQueryParam.length === 2) {
        // Check if the query parameter is a 2-letter country code
        this.countryName = this.expandCountryCode(normalizedQueryParam);
      } else {
        // Check if the query parameter matches any full country name
        this.countryName = this.$route.query.country;
      }
    }

    Auth.currentSession()
      .then(data => console.log("STDOUT: ", data))
      .catch(err => {
        console.log("ERR: ", err)
        this.signOut()
      });
  },

  mounted() {
  }
};
</script>