<template>
  <div class="border sm:w-full md:w-2/5 border-gray-300 rounded p-4 rounded-lg bg-slate-50">
    <div class="mb-3">
      <label for="addfriendName" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Name</label>
      <input
v-model="friendForm.name"
        class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-red-500 focus:border-red-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-red-500 dark:focus:border-red-500"
        id="addfriendName" aria-describedby="emailHelp"
>
    </div>

    <div class="mb-3">
      <label for="addfriendDescription" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Email</label>
      <input
v-model="friendForm.email" type="input" placeholder="muh_friend@gmail.com"
        class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-red-500 focus:border-red-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-red-500 dark:focus:border-red-500"
        id="addfriendDescription"
>
    </div>

    <div class="mb-3">
      <label for="addfriendBirthday" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Birthday</label>
      <input
v-model="friendForm.birthday" type="date"
        class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-red-500 focus:border-red-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-red-500 dark:focus:border-red-500"
        id="addfriendBirthday"
>
    </div>

    <div class="mb-3 mt-4 flex items-center">
      <input
id="reminder" type="checkbox" v-model="friendForm.reminder" :disabled="birthdaySet == false"
        class="form-checkbox h-5 w-5 text-blue-600"
>
      <div class="ml-2 text-sm">
        <label
for="reminder"
          :class="[birthdaySet == false ? ['block', 'text-sm', 'font-medium', 'text-gray-400', 'dark:text-white', 'pl-2'] : ['block', 'text-sm', 'font-medium', 'text-gray-900', 'dark:text-white', 'pl-2']]"
>
          Remind me before their birthday
        </label>
        <p id="reminder-checkbox-text" class="text-xs font-normal text-gray-500 dark:text-gray-300 pl-2">
          We'll send you a reminder 2 weeks before {{ reminderName }} birthday
        </p>
      </div>
    </div>

    <div class="mb-3">
      <label
for="friendAnniversary"
        class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
>Anniversary</label>
      <input
v-model="friendForm.anniversary" type="date"
        class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-red-500 focus:border-red-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-red-500 dark:focus:border-red-500"
        id="friendAnniversary"
>
    </div>

    <div class="mb-3 mt-4 flex items-center">
      <input
id="friendAnniversaryReminder" type="checkbox" v-model="friendForm.anniversaryReminder"
        :disabled="anniversarySet == false" class="form-checkbox h-5 w-5 text-blue-600"
>
      <div class="ml-2 text-sm">
        <label
for="friendAnniversaryReminder"
          :class="[anniversarySet == false ? ['block', 'text-sm', 'font-medium', 'text-gray-400', 'dark:text-white', 'pl-2'] : ['block', 'text-sm', 'font-medium', 'text-gray-900', 'dark:text-white', 'pl-2']]"
>
          Remind me before this anniversary
        </label>
        <p id="friendAnniversaryReminder-checkbox-text" class="text-xs font-normal text-gray-500 dark:text-gray-300 pl-2">
          We'll send you a reminder 2 weeks before your anniversary
        </p>
      </div>
    </div>

    <div class="mb-3 mt-4 flex items-center">
      <input
id="notifyRegistration" type="checkbox" v-model="friendForm.notifyRegistration" :disabled="emailSet == false"
        class="form-checkbox h-5 w-5 text-blue-600"
>
      <div class="ml-2 text-sm">
        <label
for="notifyRegistration"
          :class="[emailSet == false ? ['block', 'text-sm', 'font-medium', 'text-gray-400', 'dark:text-white', 'pl-2'] : ['block', 'text-sm', 'font-medium', 'text-gray-900', 'dark:text-white', 'pl-2']]"
>
          Let them know I've added them
        </label>
        <p id="notifyRegistration-checkbox-text" class="text-xs font-normal text-gray-500 dark:text-gray-300 pl-2">
          We will send {{ notifyName }} an email letting them know <strong>someone</strong> has added them on Present
          Planner, and invite them to join. They will not see your name or email.
        </p>
      </div>
    </div>


    <div class="flex items-center p-1 space-x-2 rounded-b border-t border-gray-200 dark:border-gray-600">
      <button
@click="createFriend" type="button"
        class="text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-red-600 dark:hover:bg-red-600 dark:focus:ring-red-800"
>
        Add
      </button>
      <button
@click="$router.go(-1)" type="button"
        class="text-gray-600 bg-white hover:bg-gray-100 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600"
>
        Cancel
      </button>
    </div>
  </div>
</template>

<script>
import { API } from 'aws-amplify';

import { createFriend } from '../graphql/mutations';

export default {
  name: 'FriendAdd',
  data() {
    return {
      friendForm: {
        name: '',
        reminder: false,
        anniversaryReminder: false,
        notifyRegistration: false,
        email: '',
      },
      emailSet: false,
      birthdaySet: false,
      anniversarySet: false,
    }
  },
  computed: {
    reminderName() {
      return this.friendForm.name === '' ? 'their' : this.friendForm.name + '\'s';
    },
    notifyName() {
      return this.friendForm.name === '' ? 'them' : this.friendForm.name;
    }
  },
  watch: {
    'friendForm.email': function (newVal, oldVal) {
      if (newVal.includes('@')) {
        this.friendForm.notifyRegistration = true;
        this.emailSet = true;
      } else {
        this.friendForm.notifyRegistration = false;
        this.emailSet = false;
      }
    },
    'friendForm.birthday': function (newVal, oldVal) {
      if (newVal) {
        this.friendForm.reminder = true;
        this.birthdaySet = true;
      } else {
        this.friendForm.reminder = false;
        this.birthdaySet = false;
      }
    },
    'friendForm.anniversary': function (newVal, oldVal) {
      if (newVal) {
        this.friendForm.anniversaryReminder = true;
        this.anniversarySet = true;
      } else {
        this.friendForm.anniversaryReminder = false;
        this.anniversarySet = false;
      }
    },
  },
  methods: {
    async createFriend() {
      var { name, email, birthday, reminder, anniversary, anniversaryReminder, notifyRegistration } = this.friendForm
      if (!birthday) { birthday = null };
      if (!anniversary) { anniversary = null };
      if (!email) { email = null };
      if (!name) return;

      const friend = { name, email, birthday, reminder, anniversary, anniversaryReminder, notifyRegistration };
      try {
        const response = await API.graphql({ query: createFriend, variables: { input: friend }, });
        this.$router.go(-1)
      } catch (error) {
      }
    },
  }
}
</script>